import React from "react"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import tw, { styled } from "twin.macro"

import Link from "@components/Link"
import useUserMode from "@hooks/useUserMode"

import LearningHubMenu from "./LearningHubMenu"
import ProductsMenu from "./ProductsMenu"

const Wrapper = tw.div`
    hidden xl:flex
    items-center gap-8
    font-bold
`

interface MenuItemProps {
    active?: boolean
}

const MenuItem = styled.span<MenuItemProps>`
    ${tw`button-1 text-main dark:text-dark-main`}
    ${tw`hover:text-primary dark:text-white dark:hover:text-primary`}
    ${({ active }) => active && tw`text-primary dark:text-primary`}
`

interface MainMenuProps {
    fixedNavbar: boolean
}

const MainMenu: React.FC<MainMenuProps> = ({ fixedNavbar }) => {
    const { t } = useTranslation("components")
    const router = useRouter()
    const { getTradeModeURL } = useUserMode()

    const tradePath = ["/trade/", "/lightning/price/"] as const
    const learningHubPath = ["/information", "/rekap", "/guide-me", "/analysis", "/campus"] as const

    const currentPath = router.route
    const walletPath = currentPath.includes("/wallet")
    const marketPath = currentPath.includes("/markets")
    const stakingPath = currentPath.includes("/staking")
    const otcPath = currentPath.includes("/OTC")

    const isActiveTradePath = tradePath.some((item) => currentPath.includes(item))
    const isActiveLearningHubPath = learningHubPath.some((item) => currentPath.includes(item))

    return (
        <Wrapper>
            <MenuItem active={marketPath}>
                <Link href='https://reku.id/markets'>{t("components:navbar.navs.market")}</Link>
            </MenuItem>
            <MenuItem active={isActiveTradePath}>
                <Link href={`https://reku.id${getTradeModeURL("BTC")}`}>{t("components:navbar.navs.trade")}</Link>
            </MenuItem>
            <ProductsMenu fixedNavbar={fixedNavbar} active={stakingPath || otcPath} />
            <MenuItem active={walletPath}>
                <Link href='https://reku.id/wallet'>{t("components:navbar.navs.wallet")}</Link>
            </MenuItem>
            <LearningHubMenu fixedNavbar={fixedNavbar} active={isActiveLearningHubPath} />
        </Wrapper>
    )
}

export default MainMenu
