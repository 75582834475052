/* eslint-disable simple-import-sort/imports */

import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { isMobile } from "react-device-detect"

import { Else, If, Then, When } from "@components/If"
import Footer from "@components/v3/Footer"
import styled from "@emotion/styled"
import useEmbedView from "@hooks/useEmbedView"

import ContentOverlay from "./ContentOverlay"
import Header from "./Header"

const LiveChat = dynamic(() => import("@components/LiveChat"), { ssr: false })
const InternetAlert = dynamic(() => import("@components/InternetAlert"), { ssr: false })

interface MobileProps {
    mobile: boolean
    embed: boolean
}

const Wrapper = styled.div<MobileProps>`
    min-width: ${({ mobile, embed }) => (mobile || embed ? "auto" : `1210px`)};

    @media screen and (max-width: 1280px) {
        min-width: auto;
    }
`

const Content = styled.div<MobileProps>`
    position: relative;

    min-width: ${({ mobile, embed }) => (mobile || embed ? "auto" : `1210px`)};
    overflow: hidden;

    @media screen and (max-width: 1280px) {
        min-width: auto;
    }
`

const onlyShowContentPath = ["/embed/help", "/recoveryphone", "/resetpassword", "/forgot-password", "recovery-auth"]

interface LayoutProps extends React.PropsWithChildren<{}> {}

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
    const router = useRouter()
    const isEmbed = useEmbedView()

    const isTradeProPath = router.asPath.includes("/trade")
    const isTradeLightningPath = router.asPath.includes("/lightning")

    const onlyShowContent = onlyShowContentPath.some((path) => router.asPath.includes(path)) && isMobile

    return (
        <If condition={onlyShowContent}>
            <Then>
                <Content mobile={isMobile} embed>
                    {children}
                </Content>
            </Then>
            <Else>
                <When condition={!isEmbed}>
                    <Wrapper mobile={isMobile} embed={isEmbed}>
                        <Header />

                        <When condition={!isTradeProPath && !isTradeLightningPath}>
                            <InternetAlert />
                        </When>
                    </Wrapper>
                </When>

                <Content mobile={isMobile} embed={isEmbed}>
                    {children}
                    <ContentOverlay />
                </Content>

                <Wrapper mobile={isMobile} embed={isEmbed}>
                    <Footer />
                </Wrapper>

                <When condition={!isEmbed}>
                    <LiveChat />
                </When>
            </Else>
        </If>
    )
}

export default Layout
