/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@redux/hooks"
import { fetchMissionPolling } from "@redux/mission-polling/actions"
import { hideMissionPolling, setTimeoutId } from "@redux/mission-polling/slicer"
import dayjs from "dayjs"

const useMissionPolling = () => {
    const { missionPolling, isLoading, isShow, timeoutId } = useAppSelector((state) => state.missionPolling)
    const { isLoggedIn } = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch()

    const update = useCallback(
        (state: boolean) => {
            dispatch(hideMissionPolling(state))

            if (!state) {
                const now = dayjs().format("YYYY-MM-DD HH:mm:ss")
                const newTimeout = dayjs(now).add(24, "hours").format("YYYY-MM-DD HH:mm:ss")

                dispatch(setTimeoutId(newTimeout))
            } else {
                dispatch(setTimeoutId(null))
            }
        },
        [dispatch]
    )

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchMissionPolling())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn])

    return {
        missionPolling,
        isLoading,
        isShow,
        update,
        timeoutId
    }
}
export default useMissionPolling
