/* eslint-disable react/jsx-props-no-spreading */
import styled from "@emotion/styled"

import * as LogoComponents from "./images"

const StyledLogo = styled.div`
    width: fit-content;
    height: fit-content;

    display: inline-flex;
    align-items: center;
    justify-content: center;
`

export interface LogoProps extends React.SVGProps<SVGSVGElement> {
    /**
     * Logo name
     */
    logo: keyof typeof LogoComponents
    /**
     * wrapper classname
     */
    wrapperClassname?: string
}

const Logo: React.FC<LogoProps> = ({ logo, wrapperClassname, ...props }: LogoProps) => {
    const Component = LogoComponents[logo]

    return (
        <StyledLogo className={wrapperClassname}>
            <Component {...props} />
        </StyledLogo>
    )
}

Logo.defaultProps = {
    wrapperClassname: undefined
}

export default Logo
