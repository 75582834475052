/* eslint-disable simple-import-sort/imports */
/* eslint-disable no-useless-return */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo, useState } from "react"
import dynamic from "next/dynamic"
import type { AppProps } from "next/app"
import { appWithTranslation } from "next-i18next"
import { Router, useRouter } from "next/router"
import { Context as ResponsiveContext } from "react-responsive"
import { Cookies, CookiesProvider } from "react-cookie"
import PageVisibility from "react-page-visibility"
import { getSelectorsByUserAgent } from "react-device-detect"
import ThemeProvider from "@styles/ThemeProvider"
import { wrapper } from "@redux/store"
import Layout from "layouts"
import { setLocale } from "@helpers/locale"

import App from "next/app"
import { isBrowser } from "@utils/browser"
import { initialCookie } from "@helpers/cookies"
import ErrorBoundary from "@app/ErrorBoundary"
import { getAuth } from "@helpers/auth"
import { updateAuth } from "@redux/auth/slicer"
import { useAppDispatch } from "@redux/hooks"

import "@styles/themes/css-variables/light.css"
import "@styles/themes/css-variables/dark.css"
import "@styles/globals.css"
import "swiper/css"
import "swiper/css/grid"
import "swiper/css/free-mode"
import "swiper/css/autoplay"
import "swiper/css/pagination"
import { fetchstatus } from "@hooks/useStatusAuth"
import throttle from "lodash.throttle"
import { COOKIE_PREFIX } from "@config/config"
import Toast from "@components/Toast"
import Script from "next/script"
import { pixelConfig } from "@config/meta-pixel"
import { setUserAgent } from "@redux/user-agent/slicer"

const GlobalLoading = dynamic(() => import("@components/LoadingIndicator/GlobalLoading"), { ssr: false })
const TopLoadingBar = dynamic(() => import("@components/TopLoadingBar"), { ssr: false })

const MyApp = ({ Component, pageProps }: AppProps) => {
    const [initialized, setInitialized] = useState(false)
    const router = useRouter()
    const dispatch = useAppDispatch()

    const throttled = throttle((action: Function) => action(), 3000, { trailing: false })

    const handleVisibilty = (visible: boolean) => {
        const auth = getAuth()
        if (auth.isLoggedIn) {
            if (visible) {
                throttled(() => fetchstatus())
            }
        }
        return
    }

    useEffect(() => {
        const auth = getAuth()
        dispatch(updateAuth(auth))

        setInitialized(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const pageView = async () => {
            const { default: analytics } = await import("@lib/analytics")
            analytics.page()

            const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
            moengageAnalytic.page()

            const { default: tiktokPixelAnalytic } = await import("@lib/tiktok-pixel-analytic")
            tiktokPixelAnalytic.page()
        }

        pageView()
    }, [router.asPath])

    useEffect(() => {
        const handleRouteChangeEnd = async () => {
            window.scroll({
                top: 1,
                left: 0,
                behavior: "smooth"
            })

            const { default: analytics } = await import("@lib/analytics")
            analytics.page()
        }

        Router.events.on("routeChangeComplete", handleRouteChangeEnd)
        Router.events.on("routeChangeError", handleRouteChangeEnd)

        return () => {
            Router.events.off("routeChangeComplete", handleRouteChangeEnd)
            Router.events.off("routeChangeError", handleRouteChangeEnd)
        }
    }, [])

    useEffect(() => {
        ;(async () => {
            setLocale(router.locale || "id")

            const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
            moengageAnalytic.identify("", {
                language: router.locale === "id" ? "Bahasa" : "English"
            })
        })()
    }, [router.locale])

    const darkModeKey = `${COOKIE_PREFIX ?? ""}reku-darkmode`

    const app = (
        <>
            <script
                id='theme-setup'
                async
                dangerouslySetInnerHTML={{
                    __html: `
                        const urlParams = new URLSearchParams(window.location.search);
                        const mode = urlParams.get('theme');

                        if (mode) {
                            const theme = document.cookie.split("; ").find((row) => row.startsWith("${darkModeKey}"));
                            const value = mode == "dark" && "1" || "0";
                            document.cookie = \`${darkModeKey}=\${value};\${theme?.split(";")[1] || ""}\`;
                        }

                        const theme = document.cookie.split("; ").find((row) => row.startsWith("${darkModeKey}"));
                        const value = theme?.split("=")[1] == "1" && "dark" || "light";
                        document.documentElement.dataset.theme = value;
                    `
                }}
            />
            <Script
                id='gtm-script'
                type='text/javascript'
                dangerouslySetInnerHTML={{
                    __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-52XLBGVR');
                    `
                }}
            />

            <Script
                id='fb-script'
                type='text/javascript'
                dangerouslySetInnerHTML={{
                    __html: `
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq("init", "${pixelConfig.appId}");
                        fbq("track", "PageView");
                    `
                }}
            />

            <PageVisibility onChange={handleVisibilty}>
                <CookiesProvider cookies={isBrowser ? undefined : new Cookies(pageProps.cookies)}>
                    <ThemeProvider>
                        <Toast />
                        <TopLoadingBar />
                        <GlobalLoading />
                        <ErrorBoundary>
                            <Layout>
                                <Component {...pageProps} />
                            </Layout>
                        </ErrorBoundary>
                    </ThemeProvider>
                </CookiesProvider>
            </PageVisibility>
        </>
    )

    const defaultSize = useMemo(
        () => ({
            width: 1280,
            height: 800
        }),
        []
    )

    return initialized ? app : <ResponsiveContext.Provider value={defaultSize}>{app}</ResponsiveContext.Provider>
}

MyApp.getInitialProps = wrapper.getInitialAppProps((store) => async (appContext) => {
    const [appProps] = await Promise.all([App.getInitialProps(appContext)])
    const userAgent = String(appContext.ctx.req?.headers["user-agent"])
    const { isMobile, isDesktop } = getSelectorsByUserAgent(userAgent)

    store.dispatch(
        setUserAgent({
            userAgent,
            isDesktop,
            isMobile
        })
    )

    const cookie = appContext.ctx.req?.headers.cookie
    if (cookie) initialCookie(cookie)

    const auth = getAuth()
    if (auth.isLoggedIn) {
        store.dispatch(updateAuth(auth))
    }

    return { ...appProps, pageProps: { ...appProps.pageProps, cookies: cookie } }
})

export default wrapper.withRedux(appWithTranslation(MyApp))
