import { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import tw, { styled } from "twin.macro"

import Link from "@components/Link"
import Icons, { IconType } from "@components/v3/Icons"
import { STAKING_MAIN_PAGE } from "@const/moengage-analytic-event"
import useScroll from "@hooks/useScroll"

import DropdownMenu from "./DropdownMenu"

interface Menu {
    icon: IconType
    title: string
    description: string
    href: string
    onClick?: () => void
}

interface MenuItemProps {
    active?: boolean
}

const MenuItem = styled.span<MenuItemProps>`
    ${tw`button-1 text-main dark:text-dark-main`}
    ${tw`hover:text-primary dark:text-white dark:hover:text-primary`}
    ${({ active }) => active && tw`text-primary dark:text-primary`}
`

const MenuWrapper = tw.div`
    flex items-center gap-1
`

const Overlay = tw.div`
    flex flex-col
    px-[6px] py-[10px]
`

const DropdownMenuItem = styled(Link)`
    ${tw`flex items-center gap-3
    py-3 pl-2 pr-2.5
    rounded-xs
    hover:bg-background-subtle dark:hover:bg-dark-background-subtle`}

    &:hover {
        .desc {
            ${tw`text-main dark:text-dark-main`}
        }
    }
`

const DropdownMenuContent = tw.div`
    flex flex-col gap-1
`

interface IconProps {
    active?: boolean
}

const Icon = styled(Icons)<IconProps>`
    ${tw`text-icon-contrast dark:text-dark-icon-contrast`}
    ${tw`transition duration-300 rotate-0`}
    ${({ active }) => active && tw`rotate-180`}
`

interface ProductsMenuProps {
    fixedNavbar: boolean
    active?: boolean
}

const ProductsMenu: React.FC<ProductsMenuProps> = ({ fixedNavbar, active }) => {
    const { t } = useTranslation("components")
    const [open, setOpen] = useState(false)
    const { scrollVertical } = useScroll()

    useEffect(() => {
        if (fixedNavbar) {
            setOpen(false)
        }
    }, [scrollVertical, fixedNavbar])

    const menu: Menu[] = [
        {
            icon: "CoinStaked",
            title: t("navbar.navs.products.staking.title"),
            description: t("navbar.navs.products.staking.description"),
            href: "https://reku.id/staking",
            onClick: () => {
                ;(async () => {
                    const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                    moengageAnalytic.track(STAKING_MAIN_PAGE)
                })()
            }
        },
        {
            icon: "OrderbookAll",
            title: t("navbar.navs.products.otc.title"),
            description: t("navbar.navs.products.otc.description"),
            href: "https://reku.id/otc"
        }
    ]

    return (
        <DropdownMenu
            open={open}
            onChange={setOpen}
            width={330}
            top={14}
            overlay={
                <Overlay>
                    {menu.map(({ icon, title, description, href, onClick }) => (
                        <DropdownMenuItem key={href} href={href} onClick={onClick}>
                            <Icons
                                icon={icon}
                                width={20}
                                height={20}
                                className='text-icon-contrast dark:text-dark-icon-contrast'
                            />
                            <DropdownMenuContent>
                                <span tw='title-4 text-main dark:text-dark-main'>{title}</span>
                                <span
                                    className='desc'
                                    tw='paragraph-4 font-normal text-additional dark:text-dark-additional'
                                >
                                    {description}
                                </span>
                            </DropdownMenuContent>
                        </DropdownMenuItem>
                    ))}
                </Overlay>
            }
        >
            <MenuItem active={active}>
                <MenuWrapper>
                    {t("navbar.navs.products.title")}
                    <Icon icon='ChevronDown' width={24} height={24} active={open} />
                </MenuWrapper>
            </MenuItem>
        </DropdownMenu>
    )
}

export default ProductsMenu
