import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { Trans, useTranslation } from "next-i18next"
import { isFirefox } from "react-device-detect"
import { useMediaQuery } from "react-responsive"
import tw, { styled } from "twin.macro"

import Link from "@components/Link"
import Container from "@components/v2/Container"
import Icons from "@components/v3/Icons"
import Logo from "@components/v3/Logo"
import { css } from "@emotion/css"
import useEmbedView from "@hooks/useEmbedView"

import MenuList from "./components/MenuList"
import { isHideFooter } from "./utils/footer"

const AbiIcon = dynamic(() => import("./images/abi"), { ssr: false })
const AspakrindoIcon = dynamic(() => import("./images/aspakrindo"), { ssr: false })
const Bappebti = dynamic(() => import("./images/bappebti"), { ssr: false })
const FacebookIcon = dynamic(() => import("./images/facebook"), { ssr: false })
const InstagramIcon = dynamic(() => import("./images/instagram"), { ssr: false })
const TelegramIcon = dynamic(() => import("./images/telegram"), { ssr: false })
const TwitterIcon = dynamic(() => import("./images/twitter"), { ssr: false })
const Iso = dynamic(() => import("./images/iso"), { ssr: false })
const Kominfo = dynamic(() => import("./images/kominfo"), { ssr: false })

const Wrapper = tw.footer`
reku-new
bg-primary dark:bg-dark-primary-50
relative pt-9 text-background
overflow-hidden max-[660px]:pr-0
`

const StyledContainer = tw(Container)`
    relative flex flex-col lg:gap-8 w-[92%] z-[2]
`

const StyledSpace = tw.div`
     w-full justify-between lg:inline-flex 
`
const CopyrightWrapper = tw.div`
     lg:inline-flex gap-3 w-full justify-between z-[2] relative max-[768px]:flex max-[768px]:flex-col-reverse
`
const InstitutionWrapper = tw.div`
    lg:max-w-[380px] mr-4 md:mb-6
`

const InstitutionLogoWrapper = tw.div`
    flex flex-wrap items-center gap-6
`
const RegisteredLogoWrapper = tw.div`
    flex flex-wrap items-center gap-4
`

const LinksWrapper = styled.div`
    ${tw`lg:w-[450px] md:flex md:gap-24 min-[1281px]:justify-between  w-[520px] max-[640px]:mt-5`}

    @media screen and (max-width: 768px) {
        ${tw`gap-1 flex-col`}
    }
`

const LinksContent = tw.div`
     h-max gap-12 
`

const CompanyWrapper = tw.div`
    lg:w-[300px] max-[768px]:mb-6
`
const Circle = styled.div`
    ${tw`z-0 absolute top-[35%] left-[50%]
    -translate-x-1/2
    w-[1000px] h-[1000px] scale-50 md:scale-100
    blur-[240px] bg-purple dark:bg-dark-purple-50
    rounded-full opacity-100`}

    @-moz-document url-prefix() {
        ${tw`opacity-70 top-[35%] left-[53%]`}
    }

    @media screen and (max-width: 768px) {
        ${tw`blur-[320px] top-[34%] left-[10%]`}
    }
`
const CircleTopLeft = styled.div`
    ${tw`z-0 absolute -top-[68%] left-[8%]
    -translate-x-1/2
    w-[720px] h-[720px] scale-50 md:scale-100
    blur-[250px] bg-purple dark:bg-dark-purple-50 
    rounded-full opacity-100`}

    @-moz-document url-prefix() {
        ${tw`-top-[68%] left-[13%] 
         h-[780px] w-[780px]
          opacity-60`}
    }
    @media screen and (max-width: 768px) {
        ${tw`blur-[250px] -top-[30%] left-[22%]`}
    }
`
const CircleTopRight = styled.div`
    ${tw`z-0 absolute -top-[40%] left-[95%]
    -translate-x-1/2
    w-[480px] h-[480px] scale-50 md:scale-100
    blur-[180px] bg-purple dark:bg-dark-purple-50 
    rounded-full opacity-100`}

    @-moz-document url-prefix() {
        ${tw`-top-[50%] left-[90%] z-0 h-[600px] w-[600px] opacity-60`}
    }
    @media screen and (max-width: 768px) {
        ${tw`blur-[220px] top-[20%] left-[90%]`}
    }
`

const BackdropFirefox = styled.div`
    ${tw`w-[600px] h-[200px] bg-purple dark:bg-dark-purple
        absolute top-0 left-[38%]
        blur-[280px] 
     
    `}

    /* @-moz-document url-prefix() { */
        ${tw`opacity-40 `} /* } */
`

const MailWrapper = tw.div`
mt-8 flex items-center max-[780px]:mt-6
`

const MapWrapper = tw.div`
 mt-4 flex max-w-[280px] max-[780px]:mt-3
`

const InfoText = tw.a`
text-[12px] lg:text-[14px] tracking-[0.15px] leading-5 ml-2.5 max-[600px]:leading-4  hover:text-[rgba(255, 255, 255, 0.9)] cursor-pointer  z-[2] relative
`

const CopyrightText = tw.h4`
text-[12px] sm:block tracking-wide 
`

const Line = tw.div`
flex-grow border-t border-grey-100 lg:mt-2.5 max-[1024px]:mb-2.5 
`

const SocialMediaWrapper = tw.div`
    inline-flex space-x-7 w-44 mt-9 max-[780px]:mt-4 md:mb-6 z-[2] relative
`
const Warning = styled.p`
    ${tw`w-full text-[10px] z-[2] relative`}
    strong {
        ${tw`font-semibold `}
    }
`

const classNameSubtitle = css`
    ${tw`title-4`}
    ${tw`flex mt-9 mb-3 max-[640px]:mt-5 !tracking-[0.15px] !font-normal !text-white`}
`

const Footer: React.FC = () => {
    const { t } = useTranslation("components")
    const router = useRouter()
    const isEmbed = useEmbedView()
    const isMobile = useMediaQuery({ maxWidth: 1279 })

    const coinCode = String(router.query.symbol)

    const settingsPath = "/settings"
    const tradePath = `/trade/${coinCode}`
    const lightPath = `/lightning/price/${coinCode}`

    const isTradPath = Boolean(tradePath === router.asPath)
    const isLightPath = Boolean(lightPath === router.asPath)
    const isSettingsPath = router.asPath.includes(settingsPath)

    if (isHideFooter(router.pathname, isEmbed)) {
        return null
    }

    if ((isTradPath && isMobile) || (isLightPath && isMobile) || (isSettingsPath && isMobile)) {
        return null
    }
    const siteUrl = "https://reku.id"

    const lists = [
        {
            title: t("components:footer.product.title"),
            links: [
                {
                    title: t("components:footer.product.crypto_transaction"),
                    url: `${siteUrl}/markets`
                },
                {
                    title: t("components:footer.product.withdraw"),
                    url: `${siteUrl}/help/cara-melakukan-withdraw-rupiah`
                },
                {
                    title: t("components:footer.product.two_fa"),
                    url: `${siteUrl}/help/cara-mengaktifkan-2-factor-authentication`
                },
                {
                    title: t("components:footer.product.affiliate"),
                    url: `${siteUrl}/settings/referral-program`
                }
            ]
        }
    ]

    const list3 = [
        {
            title: t("components:footer.information.title"),
            links: [
                {
                    title: t("components:footer.information.trading_rules"),
                    url: `${siteUrl}/trading-rule`
                },
                {
                    title: t("components:footer.information.fees"),
                    url: `${siteUrl}/fees`
                },
                {
                    title: t("components:footer.information.coinmarketcap"),
                    url: "https://coinmarketcap.com/exchanges/rekeningku-com/",
                    newTab: true
                },
                {
                    title: t("components:footer.information.blog"),
                    url: `${siteUrl}/information`
                },
                {
                    title: t("components:footer.information.terms"),
                    url: `${siteUrl}/help/syarat-dan-ketentuan-pelanggan-aset-kripto-rekeningku-com`
                },
                {
                    title: t("components:footer.information.privacy_terms"),
                    url: `${siteUrl}/help/kebijakan-privasi-reku`
                },
                {
                    title: t("components:footer.information.help"),
                    url: `${siteUrl}/help`
                },
                {
                    title: "FAQ",
                    url: `${siteUrl}/faq`
                },
                {
                    title: t("components:footer.information.api"),
                    url: "https://api.reku.id/",
                    newTab: true
                }
            ]
        }
    ]
    const lists2 = [
        {
            title: t("components:footer.company.title"),
            links: [
                {
                    title: t("components:footer.company.about_us"),
                    url: `${siteUrl}/about`
                },
                {
                    title: t("components:footer.company.career"),
                    url: `${siteUrl}/careers`
                }
            ]
        }
    ]

    return (
        <Wrapper>
            <CircleTopLeft />
            <CircleTopRight />
            <StyledContainer>
                <StyledSpace>
                    <InstitutionWrapper>
                        <Logo logo='LogotypeWhite' />
                        <div>
                            <span className={classNameSubtitle}>{t("components:footer.registed")}</span>
                            <RegisteredLogoWrapper>
                                <Link
                                    href={`${siteUrl}/help/rekeningku-com-telah-terdaftar-dan-diawasi-oleh-bappebti`}
                                    target='_blank'
                                >
                                    <Bappebti height={56} />
                                </Link>
                                <Kominfo height={56} />
                                <Link
                                    href='https://www.cbqaglobal.com/validation/company/pt-rekeningku-dotcom-indonesia/'
                                    target='_blank'
                                >
                                    <Iso height={56} />
                                </Link>
                            </RegisteredLogoWrapper>
                        </div>
                        <div>
                            <span className={classNameSubtitle}>{t("components:footer.member_of")}</span>
                            <InstitutionLogoWrapper>
                                <a href='https://asosiasiblockchain.co.id/' target='_blank' rel='noreferrer'>
                                    <AbiIcon />
                                </a>
                                <a href='https://aspakrindo.org/' target='_blank' rel='noreferrer'>
                                    <AspakrindoIcon height={48} />
                                </a>
                            </InstitutionLogoWrapper>
                        </div>
                    </InstitutionWrapper>
                    <LinksWrapper>
                        <LinksContent>
                            {lists.map((list) => (
                                <MenuList key={list.title} title={list.title} links={list.links} />
                            ))}
                        </LinksContent>
                        <LinksContent>
                            {list3.map((list) => (
                                <MenuList key={list.title} title={list.title} links={list.links} />
                            ))}
                        </LinksContent>
                    </LinksWrapper>
                    <CompanyWrapper>
                        <LinksContent>
                            {lists2.map((list) => (
                                <MenuList key={list.title} title={list.title} links={list.links} />
                            ))}
                        </LinksContent>
                        <MailWrapper>
                            <Icons icon='Mail' width={24} height={24} />
                            <InfoText href='mailto:cs@reku.id'>cs@reku.id</InfoText>
                        </MailWrapper>
                        <MapWrapper>
                            <Icons icon='Location' width={24} height={24} />
                            <InfoText
                                href='https://www.google.com/maps/place/Reku+(Rekeningku.com)/@-6.2261139,106.8088843,15z/data=!4m2!3m1!1s0x0:0x4944702b0a9627ec'
                                target='_blank'
                            >
                                Equity Tower - 11th Floor, Suite E SCBD - Jakarta Selatan 12190
                            </InfoText>
                        </MapWrapper>
                        <SocialMediaWrapper>
                            <a href='https://fb.me/reku.id' target='_blank' rel='noreferrer'>
                                <FacebookIcon />
                            </a>
                            <a href='https://www.instagram.com/reku_id' target='_blank' rel='noreferrer'>
                                <InstagramIcon />
                            </a>
                            <a href='https://twitter.com/reku_id' target='_blank' rel='noreferrer'>
                                <TwitterIcon />
                            </a>
                            <a href='https://t.me/reku_id' target='_blank' rel='noreferrer'>
                                <TelegramIcon />
                            </a>
                        </SocialMediaWrapper>
                    </CompanyWrapper>
                </StyledSpace>
                <div className='mb-7 w-full'>
                    <CopyrightWrapper className=' flex-row-reverse'>
                        <Line />
                        <CopyrightText>
                            © {new Date().getFullYear()} PT Rekeningku Dotcom Indonesia | All rights reserved.
                        </CopyrightText>
                    </CopyrightWrapper>
                    <Warning>
                        <Trans>{t("footer.warning")}</Trans>
                    </Warning>
                </div>
            </StyledContainer>
            {isFirefox && <BackdropFirefox />}
            <Circle />
        </Wrapper>
    )
}
export default Footer
