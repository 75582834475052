import { useRouter } from "next/router"
import tw, { styled } from "twin.macro"

import { Else, If, Then } from "@components/If"
import Icons from "@components/v3/Icons"
import useUserPreferences from "@hooks/useUserPreferences"

interface ToggleDarkModeProps {
    disabled?: boolean
}

const ToggleDarkMode = styled.div<ToggleDarkModeProps>`
    ${tw`flex items-center`}
    ${tw`order-2 xl:order-1`}
    ${tw`cursor-pointer`}
    ${tw`text-icon-contrast dark:text-dark-icon-contrast`}

    ${({ disabled }) => disabled && tw`cursor-not-allowed opacity-50`}
`

const disabledPath = ["/otc", "/financeflash", "/"]

const DarkModeSwitch = () => {
    const { data, update } = useUserPreferences()
    const isDarkMode = Boolean(data.darkMode)

    const router = useRouter()
    const isDisabled = !!disabledPath.find((e) => e === router.pathname)

    const handleToggleDarkMode = () => {
        if (isDisabled) return
        update({ darkMode: !data.darkMode })
    }

    return (
        <ToggleDarkMode onClick={handleToggleDarkMode} disabled={isDisabled} aria-disabled={isDisabled}>
            <If condition={isDarkMode}>
                <Then>
                    <Icons icon='Lightmode' width={24} height={24} />
                </Then>
                <Else>
                    <Icons icon='Darkmode' width={24} height={24} />
                </Else>
            </If>
        </ToggleDarkMode>
    )
}

export default DarkModeSwitch
