import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { addWatchlist, fetchWatchlist, removeWatchlist } from "./actions"

const watchlistSlicer = createSlice({
    name: "watchlist",
    initialState: {
        watchlist: [] as WatchlistModel,
        loading: false
    },
    reducers: {},
    extraReducers: {
        [fetchWatchlist.pending.type]: (state) => {
            state.loading = true
        },
        [fetchWatchlist.fulfilled.type]: (state, action: PayloadAction<WatchlistAPI>) => {
            state.loading = false
            if (action.payload) {
                state.watchlist = action.payload
            }
        },
        [addWatchlist.fulfilled.type]: (state, action: PayloadAction<AddWatchlistModel>) => {
            state.watchlist = action.payload
            state.loading = false
        },
        [removeWatchlist.fulfilled.type]: (state, action: PayloadAction<RemoveWatchlistModel>) => {
            state.watchlist = action.payload
            state.loading = false
        },
        [HYDRATE]: (state, action) => {
            if (action.payload?.watchlist?.watchlist) {
                state.watchlist = action.payload?.watchlist?.watchlist
            }
        }
    }
})

export default watchlistSlicer
