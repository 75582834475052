import React, { useEffect, useRef, useState } from "react"
import { useRouter } from "next/router"
import OutsideClickHandler from "react-outside-click-handler"
import { Transition, TransitionStatus } from "react-transition-group"
import tw, { styled } from "twin.macro"

import useContentOverlay from "@hooks/useContentOverlay"

type Placement = "bottomLeft" | "bottomRight"

interface DropdownRect {
    top: number
    left?: number
    right?: number
    placement: Placement
}

const Wrapper = tw.div`
    relative
`

interface TooltipWrapperProps {
    rect?: DropdownRect
    animationState: TransitionStatus
}

const TooltipWrapper = styled.div<TooltipWrapperProps>`
    ${tw`absolute`}
    ${tw`z-[99]`}

    top: ${({ rect }) => `${rect?.top || 0}px`};
    left: ${({ rect }) => (typeof rect?.left !== "undefined" ? `${rect.left}px` : "unset")};
    right: ${({ rect }) => (typeof rect?.right !== "undefined" ? `${rect.right}px` : "unset")};

    margin-top: ${({ animationState }) => {
        switch (animationState) {
            case "entering":
                return "-10px"
            case "entered":
                return "0px"
            case "exiting":
                return "-10px"
            case "exited":
                return "-10px"
            default:
                return "-10px"
        }
    }};

    opacity: ${({ animationState }) => {
        switch (animationState) {
            case "entering":
                return 0
            case "entered":
                return 1
            case "exiting":
                return 0
            case "exited":
                return 0
            default:
                return 0
        }
    }};
`

interface TooltipProps {
    width?: number
}

const Tooltip = styled.div<TooltipProps>`
    ${tw`relative`}
    ${tw`bg-background dark:bg-dark-background`}
    ${tw`rounded-xs`}

    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px -1px 4px -2px rgba(16, 24, 40, 0.06);

    width: ${({ width }) => (width ? `${width}px` : "max-content")};
`

interface ArrowProps {
    placement?: Placement
}

const Arrow = styled.div`
    ${tw`absolute top-[0px]`}
    ${tw`-translate-y-full`}
    ${tw`w-[16px] h-[16px]`}
    ${tw`z-[1]`}

    ${({ placement }: ArrowProps) => {
        switch (placement) {
            case "bottomLeft":
                return tw`left-[15px]`
            case "bottomRight":
                return tw`right-[15px]`
            default:
                return tw`left-[15px]`
        }
    }}

    &::before {
        content: "";
        ${tw`absolute bottom-0 [inset-inline-start: 0]`}
        ${tw`w-[16px] h-[8px]`}
        ${tw`bg-background dark:bg-dark-background`}

        clip-path: polygon(
            1.6568542494923806px 100%,
            50% 1.6568542494923806px,
            14.34314575050762px 100%,
            1.6568542494923806px 100%
        );
        clip-path: path(
            "M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z"
        );
    }

    &::after {
        content: "";
        ${tw`absolute bottom-0 [inset-inline: 0]`}
        ${tw`w-[8.970562748477143px] h-[8.970562748477143px]`}
        ${tw`m-auto`}
        ${tw`rounded-[0 0 2px 0]`}
        ${tw`shadow-[2px 2px 5px rgba(0, 0, 0, 0.05)]`}
        ${tw`z-0`}
        ${tw`bg-transparent`}
        transform: translateY(50%) rotate(-135deg);
    }
`

interface DropdownMenuProps {
    open?: boolean
    onChange?: (open: boolean) => void
    width?: number
    placement?: Placement
    overlay: React.ReactNode
    top?: number
    right?: number
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
    open: openProp,
    onChange,
    width,
    placement,
    children,
    overlay,
    top = 0,
    right = 0
}) => {
    const router = useRouter()
    const [open, setOpen] = useState(openProp)
    const [rect, setRect] = useState<DropdownRect>()
    const { openOverlay, closeOverlay } = useContentOverlay()
    const elementRef = useRef() as React.MutableRefObject<HTMLDivElement>

    const handlePosition = () => {
        const parentRect = elementRef.current?.getBoundingClientRect()

        let newRect: DropdownRect = {
            top: top + parentRect.height + 16,
            placement: placement ?? "bottomLeft"
        }

        switch (placement) {
            case "bottomLeft":
                newRect = {
                    ...newRect,
                    left: 0
                }
                break
            case "bottomRight":
                newRect = {
                    ...newRect,
                    right
                }
                break
            default:
                break
        }

        setRect(newRect)
    }

    useEffect(() => {
        handlePosition()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setOpen(openProp)
    }, [openProp])

    useEffect(() => {
        if (open) {
            openOverlay()
        } else {
            closeOverlay()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const handleClose = () => {
        setOpen(false)
        onChange?.(false)
    }

    const handleToggle = () => {
        setOpen(!open)
        onChange?.(!open)
    }

    useEffect(() => {
        handleClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.asPath])

    return (
        <div className='relative'>
            <OutsideClickHandler onOutsideClick={() => handleClose()}>
                <Wrapper ref={elementRef} onClick={handleToggle} role='button'>
                    {children}
                </Wrapper>
                <Transition in={open} timeout={100} unmountOnExit>
                    {(state) => (
                        <TooltipWrapper rect={rect} animationState={state}>
                            <Arrow placement={placement} />
                            <Tooltip width={width}>{overlay}</Tooltip>
                        </TooltipWrapper>
                    )}
                </Transition>
            </OutsideClickHandler>
        </div>
    )
}

DropdownMenu.defaultProps = {
    open: false,
    onChange: () => {},
    width: 300,
    placement: "bottomLeft",
    top: 0,
    right: 0
}

export default DropdownMenu
