import { useEffect, useRef, useState } from "react"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import dayjs from "dayjs"
import tw, { styled } from "twin.macro"

import Link from "@components/Link"
import Container from "@components/v2/Container"
import Logo from "@components/v3/Logo"
import { NAVBAR } from "@const/z-index"
import { useTheme } from "@emotion/react"
import useMissionPolling from "@hooks/useMissionPolling"
import useScroll from "@hooks/useScroll"
import { UserStatus } from "@type/model/profile"

import DarkModeSwitch from "./components/DarkModeSwitch"
import HamburgerMenu from "./components/HamburgerMenu"
import LanguageSwitch from "./components/LanguageSwitch"
import MainMenu from "./components/MainMenu"

const AuthMenu = dynamic(() => import("./components/AuthMenu"), { ssr: false })

interface WrapperProps {
    transparent?: boolean
    absolute?: boolean
    fixed?: boolean
}

const Wrapper = styled.div<WrapperProps>`
    ${tw`reku-new`}
    ${tw`w-full h-auto`}
    ${tw`flex flex-col`}
    ${tw`bg-background dark:bg-dark-background`}
    ${tw`transition duration-300`}

    ${({ transparent }) => transparent && tw`bg-transparent`}
    ${({ absolute }) => absolute && tw`absolute top-0 left-0 right-0`}
    ${({ fixed }) => fixed && tw`fixed top-0 left-0 right-0 bg-background dark:bg-dark-background`}
    ${tw`max-xl:(!sticky !top-0 !left-0 !translate-y-0)`}

    &.navbar-scrolled {
        ${tw`shadow-[0px 4px 24px rgba(218, 218, 218, 0.25)] dark:shadow-[0px 4px 24px rgba(17, 15, 31, 0.25)]`}
    }

    &.slide-up {
        ${tw`-translate-y-full`}
    }

    z-index: ${NAVBAR};
`

const HeaderWrapper = styled.div`
    ${tw`h-[48px] xl:h-[72px]`}
    ${tw`flex flex-col justify-center items-center xl:gap-2`}
`

interface StyledContainerProps {
    transparent?: boolean
    fixed?: boolean
}

const StyledContainer = styled(Container)<StyledContainerProps>`
    ${tw`flex items-center justify-between h-full`}
    ${({ transparent }) => transparent && tw`bg-transparent dark:bg-transparent shadow-none`}
    ${({ fixed }) => fixed && tw`bg-background dark:bg-dark-background`}
`

const StyledLogo = tw(Logo)`
    w-auto
    h-[28px] xl:h-[40px]
`

const RightNav = tw.div`
    flex items-center
    gap-4 xl:gap-6
`

const absoluteNavbarPath: string[] = ["/about"]
const fixedNavbarPath: string[] = []

interface HeaderProps {
    profile?: {
        riskLevelType: number
        fullName: string
        email: string
        status: UserStatus
        isLoading: boolean
        riskLevelName: string
    }
}

const Header: React.FC<HeaderProps> = ({ profile }) => {
    const router = useRouter()
    const navbarRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const { verticalDirection } = useScroll()
    const isDarkMode = useTheme().mode === "dark"

    const { update, timeoutId } = useMissionPolling()
    const [now, setNow] = useState<Date | undefined>()

    const isAbsolute = Boolean(absoluteNavbarPath.find((e) => e === router.pathname))
    const isFixed = Boolean(fixedNavbarPath.find((e) => e === router.pathname))

    useEffect(() => {
        setInterval(() => {
            const updatedDate = dayjs().format("YYYY-MM-DD HH:mm:ss")
            setNow(updatedDate as unknown as Date)
        }, 1000)

        if (timeoutId && now && now >= timeoutId) {
            update(true)
        }
    }, [now, timeoutId, update])

    useEffect(() => {
        const handleScroll = () => {
            if (isFixed) {
                if (!navbarRef.current) return
                const isTop = window.scrollY < 10

                if (verticalDirection !== "down") {
                    navbarRef.current.classList.add("slide-up")
                } else {
                    navbarRef.current.classList.remove("slide-up")
                }

                if (isTop) {
                    navbarRef.current.classList.remove("navbar-scrolled")
                    navbarRef.current.classList.remove("slide-up")
                } else {
                    navbarRef.current.classList.add("navbar-scrolled")
                }
            }
        }

        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [isFixed, verticalDirection])

    return (
        <Wrapper transparent={isAbsolute} absolute={isAbsolute} fixed={isFixed} ref={navbarRef}>
            <HeaderWrapper>
                <StyledContainer>
                    <Link href='https://reku.id/'>
                        <StyledLogo logo={!isDarkMode ? "LogotypeDefault" : "LogotypeTeal"} />
                    </Link>
                    <RightNav>
                        <MainMenu fixedNavbar={isFixed} />
                        <AuthMenu fixedNavbar={isFixed} profile={profile} />
                        <LanguageSwitch />
                        <DarkModeSwitch />
                        <HamburgerMenu />
                    </RightNav>
                </StyledContainer>
            </HeaderWrapper>
        </Wrapper>
    )
}

export default Header
