import Image from "next/image"
import { useRouter } from "next/router"
import { styled } from "twin.macro"

import AppStoreIcon from "./images/app-store"
import AppStoreIconWhite from "./images/app-store-light"
import GooglePlayIcon from "./images/google-play.webp"
import GooglePlayIconWhite from "./images/google-play-white"

interface ButtonProps {
    width?: number
    height?: number
    isGooglePlay?: boolean
}

const Button = styled.a<ButtonProps>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #000000;
    border-radius: 6px;
    color: white;
    padding: ${({ isGooglePlay }) => (isGooglePlay ? "0px 2px" : "6px 8px")};
    transition: 100ms;

    &:hover {
        background-color: #2a262c;
    }
`

interface MobileStoreButtonProps {
    store?: "android" | "ios"
    width?: number
    height?: number
    className?: string
    white?: boolean
    link?: string
}

const MobileStoreButton: React.FC<MobileStoreButtonProps> = ({
    store,
    width,
    height,
    className,
    white,
    link
}: MobileStoreButtonProps) => {
    const router = useRouter()

    const urlParam = router?.query?.onelink
    const oneLink = urlParam ? `https://reku.onelink.me/5mL7/${urlParam}` : null
    const baseUrl = "https://reku.onelink.me/5mL7/web"

    if (store === "android") {
        return (
            <Button
                isGooglePlay={!white}
                className={className}
                width={width}
                height={height}
                href={oneLink || link || baseUrl}
                target='_blank'
            >
                {white ? (
                    <GooglePlayIconWhite />
                ) : (
                    <Image priority width={width} height={height} src={GooglePlayIcon.src} alt='google-icon' />
                )}
            </Button>
        )
    }

    if (store === "ios") {
        return (
            <Button
                className={className}
                width={width}
                height={height}
                href={oneLink || link || baseUrl}
                target='_blank'
            >
                {white ? <AppStoreIconWhite /> : <AppStoreIcon />}
            </Button>
        )
    }

    return null
}

MobileStoreButton.defaultProps = {
    store: "android",
    width: 165,
    height: 46,
    className: undefined,
    white: undefined,
    link: undefined
}

export default MobileStoreButton
