import dynamic from "next/dynamic"

const IconComponents = {
    AddAddress: dynamic(() => import("./AddAddress")),
    AddressBook: dynamic(() => import("./AddressBook")),
    Airdrop: dynamic(() => import("./Airdrop")),
    AlarmClock: dynamic(() => import("./AlarmClock")),
    AlertTriangle: dynamic(() => import("./AlertTriangle")),
    Announcement: dynamic(() => import("./Announcement")),
    ArrowDown: dynamic(() => import("./ArrowDown")),
    ArrowLeft: dynamic(() => import("./ArrowLeft")),
    ArrowRight: dynamic(() => import("./ArrowRight")),
    ArrowSlantDown: dynamic(() => import("./ArrowSlantDown")),
    ArrowSlantUp: dynamic(() => import("./ArrowSlantUp")),
    ArrowUp: dynamic(() => import("./ArrowUp")),
    Attachment: dynamic(() => import("./Attachment")),
    Backspace: dynamic(() => import("./Backspace")),
    Bank: dynamic(() => import("./Bank")),
    BarChart: dynamic(() => import("./BarChart")),
    Biometric: dynamic(() => import("./Biometric")),
    Blink: dynamic(() => import("./Blink")),
    Buy: dynamic(() => import("./Buy")),
    Calendar: dynamic(() => import("./Calendar")),
    Call: dynamic(() => import("./Call")),
    CameraPlus: dynamic(() => import("./CameraPlus")),
    Card: dynamic(() => import("./Card")),
    Check: dynamic(() => import("./Check")),
    CheckCircle: dynamic(() => import("./CheckCircle")),
    ChevronDown: dynamic(() => import("./ChevronDown")),
    ChevronLeft: dynamic(() => import("./ChevronLeft")),
    ChevronRight: dynamic(() => import("./ChevronRight")),
    ChevronUp: dynamic(() => import("./ChevronUp")),
    ChevronVertical: dynamic(() => import("./ChevronVertical")),
    Clock: dynamic(() => import("./Clock")),
    CoinStaked: dynamic(() => import("./CoinStaked")),
    Copy: dynamic(() => import("./Copy")),
    Darkmode: dynamic(() => import("./Darkmode")),
    DebitCard: dynamic(() => import("./DebitCard")),
    Deposit: dynamic(() => import("./Deposit")),
    Dot: dynamic(() => import("./Dot")),
    DoubleChevronDown: dynamic(() => import("./DoubleChevronDown")),
    DoubleChevronLeft: dynamic(() => import("./DoubleChevronLeft")),
    DoubleChevronRight: dynamic(() => import("./DoubleChevronRight")),
    DoubleChevronUp: dynamic(() => import("./DoubleChevronUp")),
    Download: dynamic(() => import("./Download")),
    Drag: dynamic(() => import("./Drag")),
    Ewallet: dynamic(() => import("./Ewallet")),
    Edit: dynamic(() => import("./Edit")),
    Expand: dynamic(() => import("./Expand")),
    EyeHide: dynamic(() => import("./EyeHide")),
    EyeShow: dynamic(() => import("./EyeShow")),
    FaceId: dynamic(() => import("./FaceId")),
    Facebook: dynamic(() => import("./Facebook")),
    File: dynamic(() => import("./File")),
    FilterFunnel: dynamic(() => import("./FilterFunnel")),
    Fingerprint: dynamic(() => import("./Fingerprint")),
    GearSettings: dynamic(() => import("./GearSettings")),
    GuideMe: dynamic(() => import("./GuideMe")),
    HamburgerButton: dynamic(() => import("./HamburgerButton")),
    HeartActive: dynamic(() => import("./HeartActive")),
    Heart: dynamic(() => import("./Heart")),
    Help: dynamic(() => import("./Help")),
    History: dynamic(() => import("./History")),
    Home: dynamic(() => import("./Home")),
    Hourglass: dynamic(() => import("./Hourglass")),
    Idr: dynamic(() => import("./Idr")),
    Information: dynamic(() => import("./Information")),
    Instagram: dynamic(() => import("./Instagram")),
    Language: dynamic(() => import("./Language")),
    Lightmode: dynamic(() => import("./Lightmode")),
    LightningActive: dynamic(() => import("./LightningActive")),
    LightningInactive: dynamic(() => import("./LightningInactive")),
    LightningShape: dynamic(() => import("./LightningShape")),
    LineChartDown: dynamic(() => import("./LineChartDown")),
    LineChartUp: dynamic(() => import("./LineChartUp")),
    LiveChat: dynamic(() => import("./LiveChat")),
    LiveChat2: dynamic(() => import("./LiveChat2")),
    Location: dynamic(() => import("./Location")),
    Mail: dynamic(() => import("./Mail")),
    Master: dynamic(() => import("./Master")),
    Minus: dynamic(() => import("./Minus")),
    Money: dynamic(() => import("./Money")),
    News: dynamic(() => import("./News")),
    NotificationActive: dynamic(() => import("./NotificationActive")),
    Notification: dynamic(() => import("./Notification")),
    Option: dynamic(() => import("./Option")),
    OrderbookAll: dynamic(() => import("./OrderbookAll")),
    OrderbookAsk: dynamic(() => import("./OrderbookAsk")),
    OrderbookBid: dynamic(() => import("./OrderbookBid")),
    Password: dynamic(() => import("./Password")),
    Pin: dynamic(() => import("./Pin")),
    Plus: dynamic(() => import("./Plus")),
    PlusCircle: dynamic(() => import("./PlusCircle")),
    ProActive: dynamic(() => import("./ProActive")),
    ProInactive: dynamic(() => import("./ProInactive")),
    ProShape: dynamic(() => import("./ProShape")),
    QrScanner: dynamic(() => import("./QrScanner")),
    Receive: dynamic(() => import("./Receive")),
    Redirect: dynamic(() => import("./Redirect")),
    Referral: dynamic(() => import("./Referral")),
    Rekuiz: dynamic(() => import("./Rekuiz")),
    Resources: dynamic(() => import("./Resources")),
    Reward: dynamic(() => import("./Reward")),
    Safe: dynamic(() => import("./Safe")),
    Search: dynamic(() => import("./Search")),
    Security: dynamic(() => import("./Security")),
    Sell: dynamic(() => import("./Sell")),
    Send: dynamic(() => import("./Send")),
    Settings: dynamic(() => import("./Settings")),
    Share: dynamic(() => import("./Share")),
    Sort: dynamic(() => import("./Sort")),
    SortA: dynamic(() => import("./SortA")),
    SortZ: dynamic(() => import("./SortZ")),
    Star: dynamic(() => import("./Star")),
    SwitchHorizontal: dynamic(() => import("./SwitchHorizontal")),
    SwitchVertical: dynamic(() => import("./SwitchVertical")),
    Ticket: dynamic(() => import("./Ticket")),
    Telegram: dynamic(() => import("./Telegram")),
    Trade: dynamic(() => import("./Trade")),
    Trash: dynamic(() => import("./Trash")),
    TrendingDown: dynamic(() => import("./TrendingDown")),
    TrendingUp: dynamic(() => import("./TrendingUp")),
    Toa: dynamic(() => import("./Toa")),
    TwoFA: dynamic(() => import("./TwoFA")),
    Unstake: dynamic(() => import("./Unstake")),
    User: dynamic(() => import("./User")),
    Video: dynamic(() => import("./Video")),
    Wallet: dynamic(() => import("./Wallet")),
    Wifi: dynamic(() => import("./Wifi")),
    Withdraw: dynamic(() => import("./Withdraw")),
    Work: dynamic(() => import("./Work")),
    XCircle: dynamic(() => import("./XCircle")),
    XCloseSmall: dynamic(() => import("./XCloseSmall")),
    XClose: dynamic(() => import("./XClose")),
    VerifiedDark: dynamic(() => import("./VerifiedDark")),
    VerifiedLight: dynamic(() => import("./VerifiedLight")),
    UnverifiedDark: dynamic(() => import("./UnverifiedDark")),
    UnverifiedLight: dynamic(() => import("./UnverifiedLight")),
    WaitingDark: dynamic(() => import("./WaitingDark")),
    WaitingLight: dynamic(() => import("./WaitingLight"))
}

export default IconComponents
