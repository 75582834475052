/* eslint-disable import/no-cycle */
/* eslint-disable simple-import-sort/imports */
import axios, { AxiosAdapter, AxiosRequestConfig, AxiosRequestHeaders } from "axios"
import { throttleAdapterEnhancer } from "axios-extensions"
import { i18n } from "next-i18next"

import { getAuth } from "@helpers/auth"
import {
    MAINTENANCE,
    OTC_MAINTENANCE,
    TOKEN_INVALID,
    TOKEN_TIMEOUT,
    WRONG_EMAIL_PASSWORD,
    IP_SUSPENDED,
    USER_SUSPEND,
    USER_SUSPENDED,
    USER_SUSPEND_IN,
    REFERRAL_NOT_FOUND,
    NO_PHONE_NUMBER,
    ACCESS_DENIED,
    PROBLEMATIC_TOKEN_VALIDATION,
    UNAUTHORIZED,
    TRADING_DISABLED,
    WRONG_MIN_AMOUNT,
    WRONG_OTP,
    WRONG_PROMOCODE,
    PROMOCODE_USED,
    FAMILY_INVALID_CODE,
    FAMILY_SELF_CODE,
    EMPTY_PNL_SUMMARY,
    KEY_SETTING_NOT_FOUND,
    RESET_PASSWORD_URL_EXPIRED,
    FORGOT_PASSWORD_W_GOOGLE,
    CREATE_COIN_ADDRESS,
    EMAIL_REGISTER,
    LOGIN_EMAIL_CONFIRMATION,
    LOGIN_EMAIL_SEND,
    LOGIN_SUSPICIOUS,
    LOGIN_RESET_PASSWORD
} from "@const/errno"
import { logout } from "@redux/auth/action"
import { toast } from "@components/Toast"

import { API_URL, API_V1_URL, API_V3_URL } from "@config/config"
import querystring from "@utils/querystring"
import { isBrowser } from "@utils/browser"
import { setError } from "@redux/error/slicer"
import { close2fa } from "@redux/auth/slicer"
import storeRegistry from "@redux/registry"
import { SIGN_IN_LOCKED_OUT } from "@const/moengage-analytic-event"
import Router from "next/router"
import { getLocale } from "@helpers/locale"
import ApiError from "@class/ApiError"
import API_STATUS_CODE from "@const/api-status-code"
import Modal from "@components/v3/Modal"
import { getDeviceId } from "@config/moengage"

const useModalErrorList = [FAMILY_INVALID_CODE, FAMILY_SELF_CODE]
const validateUseModalError = (errno: number) => useModalErrorList.includes(errno)

const defaultContentType = "application/x-www-form-urlencoded"

export const client = axios.create({
    baseURL: API_URL,
    headers: {
        "Cache-Control": "no-cache"
    },
    adapter: throttleAdapterEnhancer(axios.defaults.adapter as AxiosAdapter)
})

const handleError = (message: string, errno?: number, statusCode?: number, url?: string) => {
    const store = storeRegistry.getStore()

    if (url) {
        if (url === "/walletdetail") {
            return
        }
    }

    if (statusCode && !errno) {
        if (statusCode === API_STATUS_CODE.UNAUTHORIZED) {
            store.dispatch(logout(true) as any)
            return
        }
    }

    if (errno) {
        if (
            errno === TOKEN_TIMEOUT ||
            errno === TOKEN_INVALID ||
            errno === ACCESS_DENIED ||
            errno === PROBLEMATIC_TOKEN_VALIDATION ||
            errno === UNAUTHORIZED
        ) {
            store.dispatch(logout(true) as any)
            return
        }

        const useModalError = validateUseModalError(errno)

        if (useModalError) {
            let title = message
            let description = null

            if (errno === FAMILY_INVALID_CODE) {
                title = i18n?.t("family:join_family.error.invalid_code.title") as string
                description = i18n?.t("family:join_family.error.invalid_code.subtitle") as string
            }

            Modal.error({
                title,
                description
            })

            return
        }

        if (errno === NO_PHONE_NUMBER) return
        if (errno === WRONG_MIN_AMOUNT) return
        if (errno === PROMOCODE_USED) return
        if (errno === WRONG_PROMOCODE) return
        if (errno === KEY_SETTING_NOT_FOUND) return
        if (errno === RESET_PASSWORD_URL_EXPIRED) return
        if (errno === FORGOT_PASSWORD_W_GOOGLE) return
        if (errno === CREATE_COIN_ADDRESS) return

        if (
            errno === LOGIN_EMAIL_CONFIRMATION ||
            errno === LOGIN_EMAIL_SEND ||
            errno === LOGIN_SUSPICIOUS ||
            errno === LOGIN_RESET_PASSWORD
        ) {
            const payload = {
                status: true,
                code: errno,
                message
            }

            const title = null
            const description = message
            const onClose = () => {}

            Modal.error(
                {
                    title,
                    description
                },
                { withCloseButton: false, onClose }
            )

            store.dispatch(setError(payload))
            return
        }

        if (
            errno === MAINTENANCE ||
            errno === OTC_MAINTENANCE ||
            errno === WRONG_EMAIL_PASSWORD ||
            errno === IP_SUSPENDED
        ) {
            const payload = {
                status: true,
                code: errno,
                message
            }

            if (errno === IP_SUSPENDED) {
                store.dispatch(logout(true) as any)

                if (url === "/login") {
                    ;(async () => {
                        const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                        moengageAnalytic.track(SIGN_IN_LOCKED_OUT)
                    })()
                }
            }

            store.dispatch(setError(payload))
            return
        }

        if (errno === USER_SUSPEND || errno === USER_SUSPENDED || errno === USER_SUSPEND_IN) {
            const title = message
            const description = null

            const onClose = () => {
                store.dispatch(logout(true) as any)
                if (Router.pathname === "/login") {
                    setTimeout(() => {
                        store.dispatch(close2fa())
                    }, 1000)
                }

                Router.replace("/")
            }

            Modal.error(
                {
                    title,
                    description
                },
                { onClose, withCloseButton: false }
            )

            return
        }

        if (errno === REFERRAL_NOT_FOUND) {
            return
        }

        if (errno === EMAIL_REGISTER) {
            return
        }

        // ===== trade lightning error handling =====
        if (errno === TRADING_DISABLED) {
            const payload = {
                status: true,
                code: errno,
                message
            }

            store.dispatch(setError(payload))
        }

        if (errno === WRONG_OTP && url === "/otp/verify") return
        if (errno === WRONG_OTP && url === "register/verify-otp") return
        if (errno === WRONG_OTP && url === "/transactions") return
        if (errno === WRONG_OTP && url === "/2fa/auth") return
        if (errno === EMPTY_PNL_SUMMARY) return
    }

    if (message) {
        toast.error(message, { id: message })
    }
}

const baseFetchApi = async <T = any>(config: AxiosRequestConfig): Promise<T> => {
    try {
        const { token, hash } = getAuth()
        const locale = isBrowser ? Router.locale : getLocale()

        const headers: AxiosRequestHeaders = {
            "Content-Type": defaultContentType,
            ...config?.headers
        }

        const devid = getDeviceId()

        if (devid) {
            headers.devid = devid
        }

        if (token) headers.token = token
        if (hash) headers.hash = hash
        if (locale) headers.lang = locale

        let data = config?.data || {}

        if (headers["Content-Type"] === defaultContentType) {
            data = querystring.stringify(data)
        }

        const request = await client({ ...config, data, headers })

        if (request.data.error) {
            handleError(request.data.error, request.data.errno, request.status, request.config.url)
            throw new ApiError(request.data.error, request.data, request.data.errno)
        }

        return request.data
    } catch (err) {
        if (axios.isAxiosError(err)) {
            let errorMessage = err?.response?.data?.message || err?.message
            let errno

            if (err?.response?.data?.error) errorMessage = err?.response?.data?.error
            if (err?.response?.data?.errno) errno = err?.response?.data?.errno
            if (err?.response?.data?.code) errno = err?.response?.data?.code

            handleError(errorMessage, errno, err.response?.status, config.url)
            throw new ApiError(errorMessage, err?.response?.data, errno)
        }

        throw err
    }
}

export const baseFetchApiV3 = async <T = any>(config: AxiosRequestConfig): Promise<T> =>
    baseFetchApi<T>({
        baseURL: API_V3_URL,
        ...config
    })

export const baseFetchApiV1 = async <T = any>(config: AxiosRequestConfig): Promise<T> =>
    baseFetchApi<T>({
        baseURL: API_V1_URL,
        ...config
    })

export default baseFetchApi
