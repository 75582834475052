/* eslint-disable react/jsx-props-no-spreading */
import React from "react"

import ContextIllustration from "./Context"
import EmptyStateIllustration from "./EmptyState"
import FeedbackIllustration from "./Feedback"
import PersonalityIllustration from "./Personality"

export const IllustrationComponent = {
    ...FeedbackIllustration,
    ...ContextIllustration,
    ...EmptyStateIllustration,
    ...PersonalityIllustration
}

export type IllustrationType = keyof typeof IllustrationComponent

export interface IllustrationProps extends React.SVGProps<SVGSVGElement> {
    /**
     * Illustration name
     */
    name: keyof typeof IllustrationComponent
}

const Illustration: React.FC<IllustrationProps> = ({ name, ...props }) => {
    const Component = IllustrationComponent[name]

    // eslint-disable-next-line react/jsx-props-no-spreading

    return <Component {...props} />
}

export default Illustration
