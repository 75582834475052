import classNames from "classnames"
import tw, { styled } from "twin.macro"

import { When } from "@components/If"

const variants = {
    active: {
        button: tw`bg-action-primary dark:bg-dark-action-primary hover:bg-teal-500 dark:hover:bg-dark-teal-200`,
        text: tw`text-color-bg`,
        handle: tw`bg-icon-color-bg `
    },
    inactive: {
        button: tw`bg-background-strong dark:bg-dark-grey-200 hover:bg-grey-200 dark:hover:bg-dark-grey-50`,
        text: tw`text-color-bg`,
        handle: tw`bg-icon-color-bg`
    },
    disabled: {
        button: tw`bg-action-disabled dark:bg-dark-grey-300 cursor-not-allowed`,
        text: tw`text-disabled`,
        handle: tw`bg-grey-400 dark:bg-dark-grey-400`
    }
}

const sizes = {
    sm: {
        button: tw`w-9 h-5`,
        text: tw`text-[10px] w-4 h-5`,
        handle: {
            base: tw`w-4 h-4 group-active:w-[18px]`,
            movement: tw`translate-x-4 group-active:translate-x-[14px]`
        }
    },
    md: {
        button: tw`w-12 h-6`,
        text: tw`text-xs w-6 h-6`,
        handle: {
            base: tw`w-5 h-5 group-active:w-6`,
            movement: tw`translate-x-6 group-active:translate-x-[20px]`
        }
    }
}

export type Size = keyof typeof sizes
const defaultSize: Size = "md"

interface SwitchHandleProps {
    active: boolean
    size?: Size
    disabled?: boolean
}

const SwitchHandle = styled.div<SwitchHandleProps>`
    transition: all 0.3s ease;

    ${tw`
        absolute top-0.5 left-0.5 right-auto rounded-full
        shadow-[0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)]
    `};

    ${({ size }) => sizes[size || defaultSize].handle.base};
    ${({ active, size }) => active && sizes[size || defaultSize].handle.movement};

    ${({ active, disabled }) => {
        if (disabled) return variants.disabled.handle
        if (active) return variants.active.handle
        return variants.inactive.handle
    }};
`

const Text = styled.span<SwitchHandleProps>`
    ${tw`absolute flex items-center justify-center font-semibold transition-transform duration-300`};

    ${({ size }) => sizes[size || defaultSize].text};
    ${({ active, disabled }) => {
        if (disabled) return variants.disabled.text
        if (active) return variants.active.text
        return variants.inactive.text
    }};
`

const InactiveText = styled(Text)<SwitchHandleProps>`
    left: 2px;
    ${({ active }) => !active && tw`-translate-x-8`};
`

const ActiveText = styled(Text)<SwitchHandleProps>`
    left: auto;
    right: 2px;
    ${({ active }) => active && tw`translate-x-8`};
`

const SwitchButton = styled.button<SwitchHandleProps>`
    ${tw`
        reku-new inline-flex relative overflow-hidden
        rounded-full cursor-pointer 
        transition-colors ease-linear duration-300
        focus:ring-4 focus:ring-teal-25 focus:dark:ring-dark-teal-25 focus:!outline-none
    `};

    ${({ size }) => sizes[size || defaultSize].button};

    ${({ active }) => (active ? variants.active.button : variants.inactive.button)};
    ${({ disabled }) => disabled && variants.disabled.button};
`

export interface SwitchProps {
    /**
     * Determine whether the Switch is active
     */
    active: boolean
    /**
     * Determine the size of the Switch
     */
    size?: Size
    /**
     * Trigger when the active state is changing
     */
    onChange: (active: boolean) => void
    /**
     * The component to be displayed inside active switch toggle
     */
    activeChildren?: string
    /**
     * The component to be displayed inside inactive switch toggle
     */
    inActiveChildren?: string
    /**
     * Inactive className
     */
    inactiveClassName?: string
    /**
     * Set disabled
     */
    disabled?: boolean
}

const Switch: React.FC<SwitchProps> = ({
    active,
    size,
    onChange,
    activeChildren,
    inActiveChildren,
    inactiveClassName,
    disabled
}: SwitchProps) => {
    const handleChange = () => {
        onChange(!active)
    }

    return (
        <SwitchButton
            className={classNames("group", !active && inactiveClassName)}
            active={active}
            size={size}
            onClick={handleChange}
            disabled={disabled}
        >
            <When condition={activeChildren}>
                <ActiveText active={active} size={size} disabled={disabled}>
                    {activeChildren}
                </ActiveText>
            </When>
            <SwitchHandle active={active} size={size} disabled={disabled} />
            <When condition={inActiveChildren}>
                <InactiveText active={active} size={size} disabled={disabled}>
                    {inActiveChildren}
                </InactiveText>
            </When>
        </SwitchButton>
    )
}

Switch.defaultProps = {
    size: defaultSize,
    activeChildren: undefined,
    inActiveChildren: undefined,
    inactiveClassName: undefined
}

export default Switch
