/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import tw, { styled } from "twin.macro"

import Loader from "@components/Loader"
import useUserPreferences from "@hooks/useUserPreferences"

import Icons from "../Icons"
import { IconsProps } from "../Icons/Icons"

const variants = {
    primary: {
        filled: tw`bg-action-primary text-color-bg dark:text-dark-color-bg hover:bg-teal-600 dark:bg-dark-action-primary hover:dark:bg-dark-teal-100`,
        outlined: tw`bg-transparent border text-primary dark:text-dark-primary border-action-primary hover:bg-foreground-teal dark:hover:bg-dark-foreground-teal dark:border-dark-action-primary `,
        loaderLight: "#00B8BE",
        loaderDark: "#47D2D7"
    },
    secondary: {
        filled: tw`bg-action-secondary text-color-bg dark:text-dark-color-bg hover:bg-purple-500 dark:bg-dark-action-secondary hover:dark:bg-dark-purple-200`,
        outlined: tw`bg-transparent border text-secondary dark:text-dark-secondary border-action-secondary hover:bg-foreground-purple hover:border-purple-500 dark:border-dark-action-secondary  dark:hover:bg-dark-foreground-purple`,
        loaderLight: "#6A49AB",
        loaderDark: "#B59BE9"
    },
    danger: {
        filled: tw`bg-action-danger text-color-bg dark:text-dark-color-bg hover:bg-error-600 hover:dark:bg-dark-error-200  dark:bg-action-danger`,
        outlined: tw`bg-transparent border text-error dark:text-dark-error border-action-danger dark:border-dark-action-danger hover:border-danger-600 hover:bg-foreground-red  dark:hover:bg-dark-foreground-red `,
        loaderLight: "#BE2C53",
        loaderDark: "#FF6E88"
    },
    gray: {
        filled: tw`bg-background hover:bg-background-subtle text-main dark:text-dark-main dark:bg-dark-background-strong hover:dark:bg-dark-background-subtle`,
        outlined: tw`bg-transparent border text-main border-main hover:bg-background-strong dark:text-dark-main dark:border-dark-main  dark:hover:bg-dark-background-subtle `,
        loaderLight: "#3D4A5C",
        loaderDark: "#FFFFFF"
    }
}

const sizes = {
    xs: tw`h-8 gap-0.5 text-xs py-2  px-2.5 `,
    sm: tw`h-9 gap-1 text-xs p-2.5 `,
    md: tw`h-10 gap-1 text-sm py-2.5 px-3`,
    lg: tw`h-12 gap-1.5 text-base py-3 px-4 `,
    xl: tw`h-14 gap-2 text-base py-4 px-6`
}

export type Variant = keyof typeof variants
type Size = keyof typeof sizes

const defaultSize: Size = "lg"

interface StyledButtonProps {
    /**
     * Button variant
     */
    variant?: Variant
    /**
     * Button outline
     */
    outline?: boolean
    /**
     * Disabled state of button
     */
    disabled?: boolean
    /**
     * Option to fit button width to its parent width
     */
    block?: boolean
    /**
     * Set the loading status of button
     */
    loading?: boolean
    /**
     * Set the custom height in px
     */
    height?: number
    /**
     * Set the custom width in px
     */
    width?: number
    /**
     * Button size
     */
    size?: Size
    /**
     * Icon
     */
    icon?: IconsProps["icon"]
    /**
     * Leading
     */
    iconPosition?: "leading" | "trailing"
}

const StyledButton = styled.button<StyledButtonProps>`
    ${tw`
        reku-new font-bold flex justify-center items-center rounded-[32px]
        disabled:!bg-action-disabled disabled:dark:!bg-dark-action-disabled
        disabled:!border-action-disabled disabled:dark:!border-dark-action-disabled
        disabled:text-disabled  disabled:cursor-not-allowed! disabled:dark:text-dark-disabled
    `}

    ${({ variant, outline }) => {
        if (!variant) return false
        if (outline) return variants[variant].outlined

        return variants[variant].filled
    }}

${({ size }) => sizes[size || defaultSize]}
${({ block }) => block && tw`!w-full`}

${({ outline }) => outline && tw`disabled:!bg-transparent dark:disabled:!bg-transparent`}

height: ${({ height }) => height && `${height}px !important`};
    width: ${({ width }) => width && `${width}px !important`};
`

const StyledLoader = ({ variant, outline }: ButtonProps) => {
    const darkmode = useUserPreferences().data.darkMode
    const loaderColor =
        variant === "gray" || outline
            ? darkmode
                ? variants[variant || "primary"].loaderDark
                : variants[variant || "primary"].loaderLight
            : "#ffffff"

    return <Loader type='ThreeDots' height={15} width={40} color={loaderColor} />
}

export interface ButtonProps
    extends React.PropsWithChildren<StyledButtonProps>,
        React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    /**
     * Change HTML tag or custom component with keep styling
     */
    as?: React.ElementType<any>
}

const Button: React.FC<ButtonProps> = ({
    children,
    variant,
    outline,
    disabled,
    block,
    loading,
    height,
    width,
    size,
    icon,
    iconPosition,
    ...props
}: ButtonProps) => {
    const iconSize = size === "lg" ? 24 : 20

    return (
        <StyledButton
            variant={variant}
            outline={outline}
            disabled={disabled}
            block={block}
            height={height}
            width={width}
            size={size}
            {...props}
        >
            {!loading ? (
                <>
                    {iconPosition === "leading" && icon && <Icons icon={icon} width={iconSize} height={iconSize} />}
                    {children}
                    {iconPosition === "trailing" && icon && <Icons icon={icon} width={iconSize} height={iconSize} />}
                </>
            ) : (
                <StyledLoader variant={variant} outline={outline} />
            )}
        </StyledButton>
    )
}

Button.defaultProps = {
    variant: "primary",
    outline: false,
    block: false,
    disabled: false,
    loading: false,
    height: undefined,
    width: undefined,
    size: undefined,
    iconPosition: "trailing"
}

export default Button
