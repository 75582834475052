/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable simple-import-sort/imports */
import React, { useEffect, useState } from "react"
import tw, { styled } from "twin.macro"
import { useTranslation } from "next-i18next"
import classNames from "classnames"
import { useRouter } from "next/router"

import Icons from "@components/v3/Icons"
import Link from "@components/Link"
import { Else, If, Then, When } from "@components/If"
import { IconsProps } from "@components/v3/Icons/Icons"
import Collapse from "@components/Collapse"
import ConditionalWrapper from "@components/ConditionalWrapper"
import { Button } from "@components/v3/Button"
import Divider from "@components/v2/Divider"
import MobileStoreButton from "@components/v2/MobileStoreButton"
import useAuth, { useLogout } from "@hooks/useAuth"
import useProfile from "@hooks/useProfile"
import Avatar from "@components/v3/Avatar"
import UserStatusIcon from "@components/UserStatusIcon"
import PersonalityAvatar from "@components/PersonalityAvatar"
import CTAPersonality from "@components/CTAPersonality"

const Wrapper = tw.div`
    h-full
    flex xl:hidden
    items-center justify-center
    order-2 xl:order-1
`

interface CollapseMenuProps {
    isOpen: boolean
}

const CollapseMenu = styled.div<CollapseMenuProps>`
    ${tw`fixed top-[48px] xl:top-[72px] left-0 z-10`}
    ${tw`w-full h-[calc(100vh-48px)] xl:h-[calc(100vh-72px)]`}
    ${tw`bg-background dark:bg-dark-background`}
    ${tw`p-4`}
    ${tw`flex flex-col justify-between`}
    ${tw`overflow-y-auto reku-scrollbar`}
    ${tw`transition-all duration-300 ease-in-out`}

    ${({ isOpen }) => !isOpen && tw`!h-[0px] py-0`}
`

const NavsWrapper = tw.div`
    flex flex-col gap-4
`

const Navs = tw.nav`
    flex flex-col gap-2
`

interface NavItemProps {
    isActive?: boolean
}

const NavItem = styled.div<NavItemProps>`
    ${tw`w-full h-[32px]`}
    ${tw`flex items-center justify-between`}
    ${tw`button-2 text-main dark:text-dark-main`}
    ${tw`cursor-pointer`}

    ${({ isActive }) => isActive && tw`text-primary dark:text-dark-primary`}
`

const NavChildrenItem = tw(NavItem)`
    h-[46px]
    flex items-center justify-start gap-3
`

const NavChildrenItemContent = tw.div`
    flex flex-col gap-0.5
`

const NavChildrenItemTitle = tw.span`
    title-4
`

const NavChildrenItemDescription = tw.span`
    paragraph-4 font-normal text-additional dark:text-dark-additional line-clamp-1
`

const Footer = tw.div`
    w-full
    flex flex-col
`

const FooterButton = tw.div`
    w-full
    py-4
    flex gap-4
`

const FooterApp = tw.div`
    w-full
    py-4
    flex flex-col items-center gap-4
`

const FooterAppTitle = tw.span`
    title-5 text-additional dark:text-dark-additional
`

const FooterAppList = tw.div`
    flex gap-4
`

interface NavChildrenProps {
    href: string
    icon: IconsProps["icon"]
    label: string
    description: string
}

interface NavProps {
    href?: string
    label: string
    childs?: Array<NavChildrenProps>
    getActive?: (currentPathname: string) => boolean
}

interface NavChildrenItemsProps {
    items: Array<NavChildrenProps>
    isExpanded: boolean
    onClick?: () => void
}

const NavChildrenItems: React.FC<NavChildrenItemsProps> = ({ items, isExpanded, onClick }) => {
    const { t } = useTranslation("components")

    const renderedNavs = items.map(({ href, icon, label, description }) => (
        <ConditionalWrapper
            key={href}
            condition={!!href}
            wrapper={(children) => (
                <Link href={href} onClick={onClick} external>
                    {children}
                </Link>
            )}
        >
            <NavChildrenItem>
                <Icons icon={icon} width={20} height={20} tw='text-icon-contrast dark:text-dark-icon-contrast' />
                <NavChildrenItemContent>
                    <NavChildrenItemTitle>{t(`navbar.navs.${label}`)}</NavChildrenItemTitle>
                    <NavChildrenItemDescription>{t(`navbar.navs.${description}`)}</NavChildrenItemDescription>
                </NavChildrenItemContent>
            </NavChildrenItem>
        </ConditionalWrapper>
    ))

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Collapse isExpanded={isExpanded}>{renderedNavs}</Collapse>
}

NavChildrenItems.defaultProps = {
    onClick: () => {}
}

interface NavItemsProps extends Omit<NavProps, "getActive"> {
    isActive?: boolean
    onClick?: () => void
}

const NavItems: React.FC<NavItemsProps> = ({ href, label, childs, isActive, onClick }) => {
    const { t } = useTranslation("components")
    const [isExpanded, setExpanded] = useState(false)

    const handleExpand = () => {
        if (!childs) {
            return
        }

        setExpanded(!isExpanded)
    }

    return (
        <>
            <ConditionalWrapper
                condition={!!href}
                wrapper={(children) => (
                    <Link href={String(href)} onClick={onClick} external>
                        {children}
                    </Link>
                )}
            >
                <NavItem onClick={handleExpand} isActive={isActive || isExpanded}>
                    {t(`navbar.navs.${label}`)}
                    <When condition={childs}>
                        <Icons
                            icon='ChevronDown'
                            width={24}
                            height={24}
                            tw='text-icon-contrast dark:text-dark-icon-contrast transition-transform duration-300'
                            className={classNames({
                                "transform rotate-180": isExpanded,
                                "!text-primary dark:!text-dark-primary": isExpanded
                            })}
                        />
                    </When>
                </NavItem>
            </ConditionalWrapper>
            <When condition={childs}>
                {childs && <NavChildrenItems items={childs} isExpanded={isExpanded} onClick={onClick} />}
            </When>
        </>
    )
}

NavItems.defaultProps = {
    isActive: false,
    onClick: () => {}
}

const ProfileWrapper = tw.div`
    flex gap-4
`

const ProfileInfoWrapper = tw.div`
    flex flex-[1] flex-col gap-2
`

const ProfileInfo = tw.div`
    flex flex-col xl:gap-1 gap-2.5
`

const ProfileName = tw.span`
    title-3 text-bold dark:text-dark-bold
    line-clamp-1
`

const ProfileEmail = tw.span`
    paragraph-4 text-main dark:text-dark-main
    line-clamp-1
`

const NameWrapper = tw.div`flex items-center gap-2`

const ProfileSettingsWrapper = tw.div``

interface ProfileProps {
    onCTAClick: () => void
}

const Profile: React.FC<ProfileProps> = ({ onCTAClick }) => {
    const { profile } = useProfile()

    return (
        <ProfileWrapper>
            <If condition={profile.riskLevelName}>
                <Then>
                    <PersonalityAvatar width={72} height={72} riskLevelType={Number(profile.riskLevelType)} />
                </Then>
                <Else>
                    <Avatar name={profile.fullName} size='md' />
                </Else>
            </If>
            <ProfileInfoWrapper>
                <ProfileInfo>
                    <NameWrapper>
                        <ProfileName>{profile.fullName}</ProfileName>
                        <When condition={profile.fullName}>
                            <UserStatusIcon status={profile.status} />
                        </When>
                    </NameWrapper>
                    <CTAPersonality onClick={onCTAClick} riskLevelName={profile.riskLevelName} />
                    <NameWrapper>
                        <ProfileEmail>{profile.email}</ProfileEmail>
                        <When condition={!profile.fullName}>
                            <UserStatusIcon status={profile.status} />
                        </When>
                    </NameWrapper>
                </ProfileInfo>
            </ProfileInfoWrapper>
            <ProfileSettingsWrapper>
                <Link href='https://reku.id/settings' external>
                    <Icons
                        icon='GearSettings'
                        width={24}
                        height={24}
                        tw='text-icon-contrast dark:text-dark-icon-contrast'
                    />
                </Link>
            </ProfileSettingsWrapper>
        </ProfileWrapper>
    )
}

const navs: Array<NavProps> = [
    {
        href: "https://reku.id/markets",
        label: "market",
        getActive: (currentPathname: string) => currentPathname.includes("/markets")
    },
    {
        href: "https://reku.id/trade/BTC-IDR",
        label: "trade",
        getActive: (currentPathname: string) => currentPathname.includes("/trade")
    },
    {
        href: "https://reku.id/OTC",
        label: "products.otc.title",
        getActive: (currentPathname: string) => currentPathname.includes("/otc")
    },
    {
        href: "https://reku.id/wallet",
        label: "wallet",
        getActive: (currentPathname: string) => currentPathname.includes("/wallet")
    },
    {
        label: "learning_hub.title",
        childs: [
            {
                href: "https://reku.id/information",
                icon: "Information",
                label: "learning_hub.info.title",
                description: "learning_hub.info.description"
            },
            {
                href: "https://reku.id/rekap",
                icon: "GuideMe",
                label: "learning_hub.rekap.title",
                description: "learning_hub.rekap.description"
            },
            {
                href: "https://reku.id/analysis",
                icon: "Star",
                label: "learning_hub.analysis.title",
                description: "learning_hub.analysis.description"
            },
            {
                href: "https://reku.id/campus",
                icon: "News",
                label: "learning_hub.campus.title",
                description: "learning_hub.campus.description"
            }
        ],
        getActive: (currentPathname: string) => {
            const regex = /\/(information|rekap|analysis|campus)/
            return regex.test(currentPathname)
        }
    },
    {
        href: "https://reku.id/help",
        label: "help_center",
        getActive: (currentPathname: string) => currentPathname.includes("/help")
    },
    {
        href: "https://reku.id/livechat",
        label: "livechat",
        getActive: (currentPathname: string) => currentPathname.includes("/livechat")
    }
]

const HamburgerMenu: React.FC = () => {
    const { t } = useTranslation("components")
    const router = useRouter()
    const [isOpen, setOpen] = useState(false)
    const { auth } = useAuth()
    const { logout } = useLogout()

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "unset"
        }
    }, [isOpen])

    const handleToggle = () => setOpen(!isOpen)
    const handleClose = () => setOpen(false)

    useEffect(() => {
        handleClose()
    }, [router.asPath])

    const renderedNavs = navs.map(({ href, label, childs, getActive }) => (
        <NavItems
            key={label}
            href={href}
            label={label}
            childs={childs}
            isActive={getActive?.(router.asPath)}
            onClick={handleClose}
        />
    ))

    return (
        <Wrapper>
            <Icons
                icon={!isOpen ? "HamburgerButton" : "XClose"}
                width={24}
                height={24}
                tw='text-icon-contrast dark:text-dark-icon-contrast cursor-pointer'
                onClick={handleToggle}
            />
            <CollapseMenu isOpen={isOpen}>
                <NavsWrapper>
                    <When condition={auth.isLoggedIn}>
                        <Profile onCTAClick={handleClose} />
                    </When>
                    <Navs>{renderedNavs}</Navs>
                </NavsWrapper>
                <Footer>
                    <FooterButton>
                        <If condition={auth.isLoggedIn}>
                            <Then>
                                <Button variant='primary' size='lg' outline block onClick={() => logout()}>
                                    {t("navbar.navs.logout")}
                                </Button>
                            </Then>
                            <Else>
                                <Link href='https://reku.id/login' tw='w-full' external>
                                    <Button variant='primary' size='lg' outline block>
                                        {t("navbar.navs.sign_in")}
                                    </Button>
                                </Link>
                                <Link href='https://reku.id/register' tw='w-full' external>
                                    <Button variant='primary' size='lg' block>
                                        {t("navbar.navs.sign_up")}
                                    </Button>
                                </Link>
                            </Else>
                        </If>
                    </FooterButton>
                    <Divider variant='subtle' />
                    <FooterApp>
                        <FooterAppTitle>{t("navbar.navs.download_apps")}</FooterAppTitle>
                        <FooterAppList>
                            <MobileStoreButton store='android' tw='w-auto flex-1 py-[6px] px-2' height={40} />
                            <MobileStoreButton store='ios' tw='w-auto flex-1' height={40} />
                        </FooterAppList>
                    </FooterApp>
                </Footer>
            </CollapseMenu>
        </Wrapper>
    )
}

export default HamburgerMenu
