/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from "react"
import { useCollapse } from "react-collapsed"
import { useMediaQuery } from "react-responsive"
import tw, { styled } from "twin.macro"

import Link from "@components/Link"
import Icons from "@components/v3/Icons"
import { css } from "@emotion/css"
import Space from "@layouts/Space"

interface ChevronIconProps {
    isExpanded: boolean
}

const ChevronIcon = styled(Icons)<ChevronIconProps>`
    transform: ${({ isExpanded }) => (isExpanded ? "rotate(0deg)" : "rotate(-90deg)")};
    transition: transform 0.3s ease;

    @media screen and (min-width: 769px) {
        ${tw`hidden`}
    }
`

const Links = styled.div`
    ${tw`!flex flex-col z-[2]`}

    @media screen and (max-width: 660px) {
        line-height: 27px;
    }
`

const StyledA = styled.a`
    ${tw`w-fit mt-1.5 xl:text-[14px] text-xs font-normal z-[2]`}

    &:hover {
        color: rgba(255, 255, 255, 0.9);
    }
`

const StyledLink = styled(Link)`
    ${tw`w-fit h-fit mt-1.5 text-[14px] leading-5  tracking-[0.15px] font-normal z-[2] `}

    display: unset;

    &:hover {
        color: rgba(255, 255, 255, 0.9);
    }

    @media screen and (max-width: 1279px) {
        ${tw`flex items-center !text-xs `}
    }
`

interface Props {
    title: string
    links: {
        title: string
        url: string
        newTab?: boolean
        external?: boolean
    }[]
}

const titleClassName = css`
    ${tw`title-3`}
    ${tw`!tracking-[2px] z-[2] !text-white  `}
    @media screen and (max-width: 768px) {
        ${tw`flex items-center !text-sm !leading-4`}
    }
`

const MenuList: React.FC<Props> = ({ title, links }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 })
    const [isExpanded, setExpanded] = useState(false)
    const { getToggleProps, getCollapseProps } = useCollapse({ isExpanded: !isMobile ? true : isExpanded })

    return (
        <Space direction='vertical' className='mt-1.5 ' key={title}>
            <span
                className={titleClassName}
                onClick={getToggleProps({ onClick: () => setExpanded(!isExpanded) }).onClick}
            >
                {title}
                <ChevronIcon icon='ChevronDown' width={24} height={24} isExpanded={isExpanded} />
            </span>
            <Links {...getCollapseProps()}>
                {links.map((link) => {
                    if (!link.url.includes("http") && !link.external) {
                        return (
                            <StyledLink key={link.url} href={link.url} target={link?.newTab ? "_blank" : undefined}>
                                {link.title}
                            </StyledLink>
                        )
                    }

                    return (
                        <StyledA key={link.url} href={link.url} target={link?.newTab ? "_blank" : undefined}>
                            {link.title}
                        </StyledA>
                    )
                })}
            </Links>
        </Space>
    )
}

export default MenuList
