import Image from "next/image"
import tw, { styled } from "twin.macro"

import { Case, Default, Switch } from "@components/If"
import Skeleton from "@components/Skeleton"
import Icons from "@components/v2/Icons"

import { getInitialName } from "./utils"

const sizes = {
    sm: tw`w-[48px] h-[48px] text-sm leading-5`,
    md: tw`w-[72px] h-[72px] text-lg leading-7`,
    lg: tw`w-[106px] h-[106px] text-[32px] leading-[46px]`
}

type Size = keyof typeof sizes
const defaultSize = "md"

const getSize = (size: Size) => {
    switch (size) {
        case "sm":
            return 48
        case "md":
            return 72
        case "lg":
            return 106
        default:
            return 72
    }
}

interface WrapperProps {
    size?: Size
    focus?: boolean
}

const Wrapper = styled.div<WrapperProps>`
    ${tw`
        reku-new flex items-center justify-center rounded-full overflow-hidden
        bg-foreground-teal dark:bg-dark-foreground-teal
        border border-soft dark:border-dark-soft
    `};

    ${({ size }) => sizes[size || defaultSize]};
    ${({ focus }) => focus && tw`ring-4 ring-teal-100 dark:ring-dark-teal-100`};
`

const AvatarName = tw.span`
    text-primary dark:text-dark-primary font-semibold
`

const StyledIcons = tw(Icons)`
    text-primary
`

interface AvatarProps {
    /**
     * Text avatars should be used when there is no image available for the user, or as a fallback when the user defined image fails to display.
     * The font size is set to allow a maximum of 2 characters of Latin script at the small size.
     */
    name: string
    /**
     * Where possible, allow a user to include an image with their avatar to give the user the impression of personalized content even more.
     * Images passed to the component are cropped into a circle.
     */
    image?: string
    /**
     * Avatar size
     */
    size?: Size
    /**
     * Avatar loading
     */
    loading?: boolean
    /**
     * Set focus attribute
     */
    focus?: boolean
}

const Avatar: React.FC<AvatarProps> = ({ name, image, size = defaultSize, loading, focus }: AvatarProps) => (
    <Switch>
        <Case condition={loading}>
            <Skeleton width={getSize(size)} height={getSize(size)} circle />
        </Case>
        <Case condition={image}>
            <Wrapper focus={focus} size={size}>
                <Image src={image || ""} alt={`${name} image`} width={getSize(size)} height={getSize(size)} />
            </Wrapper>
        </Case>
        <Case condition={name}>
            <Wrapper focus={focus} size={size}>
                <AvatarName>{getInitialName(name)}</AvatarName>
            </Wrapper>
        </Case>
        <Default>
            <Wrapper focus={focus} size={size}>
                <StyledIcons icon='User' width={getSize(size) / 2} height={getSize(size) / 2} />
            </Wrapper>
        </Default>
    </Switch>
)

Avatar.defaultProps = {
    image: undefined,
    size: "md",
    loading: false,
    focus: false
}

export default Avatar
