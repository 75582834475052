import tw from "twin.macro"

const BaseIndicator = tw.div`
    reku-new
    bg-icon-error dark:bg-dark-icon-error
    rounded-sm
    border border-solid border-background dark:border-dark-background
`

export default BaseIndicator
