import { useTranslation } from "next-i18next"
import { useMediaQuery } from "react-responsive"
import tw from "twin.macro"

import NewIndicator from "@components/Indicator/NewIndicator"
import Link from "@components/Link"
import Icons from "@components/v3/Icons"

interface CTAPersonalityProps {
    /**
     * The information regarding user risk level
     */
    riskLevelName: string | undefined
    /**
     * onClick event handler
     */
    onClick?: () => void
}

const Wrapper = tw.div`
    reku-new flex items-center gap-1
`
const Text = tw.div`xl:paragraph-2 paragraph-4 text-primary dark:text-dark-primary`

const CTAPersonality: React.FC<CTAPersonalityProps> = ({ riskLevelName, onClick }: CTAPersonalityProps) => {
    const { t } = useTranslation()
    const isMobile = useMediaQuery({ maxWidth: 1279 })

    return (
        <Link
            onClick={onClick}
            href={riskLevelName ? "/settings/investor-personality-profile" : "/settings/investor-personality"}
        >
            <Wrapper>
                {!riskLevelName && <NewIndicator tw='!mt-1' />}
                {riskLevelName ? <Text>{riskLevelName}</Text> : <Text>{t("common:personality.sidebar_title")}</Text>}
                <Icons
                    icon='ChevronRight'
                    width={isMobile ? 18 : 24}
                    height={isMobile ? 18 : 24}
                    className='text-icon-primary xl:ml-0 -ml-1 dark:text-dark-icon-primary'
                />
            </Wrapper>
        </Link>
    )
}

CTAPersonality.defaultProps = {
    onClick: undefined
}

export default CTAPersonality
