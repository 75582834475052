/* eslint-disable react/jsx-props-no-spreading */
import tw, { styled } from "twin.macro"
import { UrlObject } from "url"

import Link from "@components/Link"

const variants = {
    primary: tw`text-primary dark:text-dark-primary hover:text-teal-600 dark:hover:text-dark-teal-300`,
    secondary: tw`text-action-secondary hover:text-secondary dark:text-dark-secondary dark:hover:text-dark-purple-300`,
    danger: tw`text-error hover:text-error-800 dark:text-dark-error dark:hover:text-dark-error-400`,
    white: tw`text-color-bg hover:text-teal-600 dark:text-dark-color-bg dark:hover:text-dark-teal-300`,
    gray: tw`text-main hover:text-bold dark:text-dark-color-bg dark:hover:text-dark-teal-300`
}

const sizes = {
    sm: tw`text-xs  `,
    md: tw`text-sm`,
    lg: tw`text-base`
}

export type Variant = keyof typeof variants
type Size = keyof typeof sizes

const defaultSize: Size = "lg"

interface StyledButtonProps {
    /**
     * Button variant
     */
    variant?: Variant
    /**
     * Button outline
     */
    disabled?: boolean
    /**
     * Option to fit button width to its parent width
     */
    block?: boolean
    /**
     * Set the loading status of button
     */
    loading?: boolean
    /**
     * Button size
     */
    size?: Size
}

const StyledButton = styled.button<StyledButtonProps>`
    ${tw`
        reku-new !font-bold
        disabled:!text-disabled  disabled:cursor-not-allowed! disabled:dark:!text-dark-disabled
    `}

    ${({ variant }) => {
        if (!variant) return false
        return variants[variant]
    }}

${({ size }) => sizes[size || defaultSize]}
${({ block }) => block && tw`!w-full`}
`

export interface ButtonLinkProps
    extends React.PropsWithChildren<StyledButtonProps>,
        React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    /**
     * Change HTML tag or custom component with keep styling
     */
    as?: React.ElementType<any>

    /**
     * href link
     */
    href?: string | UrlObject
    /**
     * Target
     */
    target?: "_blank"
    /**
     * Locale
     */
    locale?: string
    /**
     * External Link
     */
    external?: boolean
    /**
     * onClick
     */
    onClick?: () => void
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
    children,
    variant,
    disabled,
    block,
    loading,
    size,
    href,
    target,
    locale,
    onClick,
    external,
    ...props
}: ButtonLinkProps) => {
    if (!href) {
        return (
            <StyledButton variant={variant} disabled={disabled} block={block} size={size} onClick={onClick} {...props}>
                {children}
            </StyledButton>
        )
    }
    return (
        <StyledButton variant={variant} disabled={disabled} block={block} size={size} {...props}>
            <Link href={href} onClick={onClick} locale={locale} target={target} external={external}>
                {children}
            </Link>
        </StyledButton>
    )
}

ButtonLink.defaultProps = {
    variant: "primary",
    block: false,
    disabled: false,
    loading: false,
    size: undefined,
    target: undefined,
    onClick: undefined,
    external: undefined
}

export default ButtonLink
