/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import classnames from "classnames"
import tw, { styled } from "twin.macro"

import Icons from "../Icons"
import { IconsProps } from "../Icons/Icons"

const variants = {
    primary: {
        filled: tw`bg-action-primary hover:bg-teal-600 dark:bg-dark-action-primary hover:dark:bg-dark-teal-100`,
        outlined: tw`bg-transparent border  border-action-primary hover:bg-foreground-teal dark:hover:bg-dark-foreground-teal dark:border-dark-action-primary text-primary dark:text-dark-primary hover:text-teal-600 dark:hover:text-dark-teal-600 `,
        loaderOutline: tw`text-primary dark:text-dark-primary`
    },
    secondary: {
        filled: tw`bg-action-secondary hover:bg-purple-500 dark:bg-dark-action-secondary hover:dark:bg-dark-purple-200`,
        outlined: tw`bg-transparent border border-action-secondary hover:bg-foreground-purple hover:border-purple-500 dark:border-dark-action-secondary  dark:hover:bg-dark-foreground-purple text-secondary hover:text-purple-600 dark:text-dark-secondary dark:hover:text-dark-icon-secondary`,
        loaderOutline: tw`text-action-secondary hover:text-secondary dark:text-dark-icon-secondary dark:hover:text-dark-purple-400`
    },
    danger: {
        filled: tw`bg-action-danger hover:bg-error-600 hover:dark:bg-dark-error-200  dark:bg-action-danger`,
        outlined: tw`bg-transparent border text-error dark:text-dark-error border-action-danger dark:border-dark-action-danger hover:border-danger-600 hover:bg-foreground-red  dark:hover:bg-dark-foreground-red text-error hover:text-error-600 dark:text-dark-error dark:hover:text-dark-icon-error`,
        loaderOutline: tw`text-error dark:text-dark-icon-error`
    }
}

const sizes = {
    xs: tw`h-9 w-9 `,
    sm: tw`h-12 w-12`,
    md: tw`h-14 w-14`,
    lg: tw`h-16 w-16`
}

type Variant = keyof typeof variants
type Size = keyof typeof sizes

const defaultSize: Size = "lg"

interface StyledButtonProps {
    /**
     * Button variant
     */
    variant?: Variant
    /**
     * Button outline
     */
    outline?: boolean
    /**
     * Disabled state of button
     */
    disabled?: boolean
    /**
     * Set the loading status of button
     */
    loading?: boolean
    /**
     * Set the custom height in px
     */
    height?: number
    /**
     * Set the custom width in px
     */
    width?: number
    /**
     * Button size
     */
    size?: Size
    /**
     * Icon
     */
    icon?: IconsProps["icon"]
}

const StyledButton = styled.button<StyledButtonProps>`
    ${tw`
        reku-new  flex justify-center items-center rounded-full 
        disabled:!bg-action-disabled disabled:dark:!bg-dark-action-disabled
        disabled:border-action-disabled disabled:dark:border-dark-action-disabled
        disabled:!text-disabled  disabled:cursor-not-allowed! disabled:dark:!text-dark-disabled
    `}

    ${({ variant, outline }) => {
        if (!variant) return false
        if (outline) return variants[variant].outlined

        return variants[variant].filled
    }}

    ${({ size }) => sizes[size || defaultSize]}

    ${({ outline }) => outline && tw`disabled:!bg-transparent dark:disabled:!bg-transparent`}

    height: ${({ height }) => height && `${height}px !important`};
    width: ${({ width }) => width && `${width}px !important`};
`

export interface ButtonIconProps
    extends React.PropsWithChildren<StyledButtonProps>,
        React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    /**
     * Change HTML tag or custom component with keep styling
     */
    as?: React.ElementType<any>
}

const ButtonIcon: React.FC<ButtonIconProps> = ({
    variant,
    outline,
    disabled,
    loading,
    height,
    width,
    size,
    icon,
    ...props
}: ButtonIconProps) => {
    const iconSize = size === "xs" ? 20 : size === "sm" || size === "md" ? 24 : size === "lg" ? 32 : 24
    const iconClassName = classnames({
        "text-icon-color-bg dark:text-dark-icon-color-bg": !outline && !disabled,
        "text-icon-disabled dark:text-dark-icon-disabled": !outline && disabled
    })
    return (
        <StyledButton
            variant={variant}
            outline={outline}
            disabled={disabled}
            height={height}
            width={width}
            size={size}
            {...props}
        >
            {icon && <Icons icon={icon} className={iconClassName} width={iconSize} height={iconSize} />}
        </StyledButton>
    )
}

ButtonIcon.defaultProps = {
    variant: "primary",
    outline: false,
    disabled: false,
    loading: false,
    height: undefined,
    width: undefined,
    size: undefined,
    icon: "Plus"
}

export default ButtonIcon
