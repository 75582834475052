import { useState } from "react"
import { useRouter } from "next/router"
import tw from "twin.macro"

import { LanguageSwitch as LanguageSwitchComponent } from "@components/v3/Switch"
import useLang from "@hooks/useLang"

const Wrapper = tw.div`
    flex items-center order-1
`

const LanguageSwitch = () => {
    const router = useRouter()
    const [checked, setChecked] = useState(router.locale === "id")
    const { updateLang } = useLang()

    const handleChange = () => {
        setChecked(!checked)

        const lang = checked ? "en" : "id"
        updateLang({ lang })

        setTimeout(() => {
            router.replace(router.asPath, router.asPath, { locale: lang })
        }, 300)
    }

    return (
        <Wrapper>
            <LanguageSwitchComponent active={checked} onChange={handleChange} />
        </Wrapper>
    )
}

export default LanguageSwitch
