import CurrencyIdr from "./currency-idr.svg"
import LogogramDefault from "./logogram-default.svg"
import LogogramPurple from "./logogram-purple.svg"
import LogogramTeal from "./logogram-teal.svg"
import LogotypeBlack from "./logotype-black.svg"
import LogotypeDefault from "./logotype-default.svg"
import LogotypePurple from "./logotype-purple.svg"
import LogotypeReverseBlack from "./logotype-reverse-black.svg"
import LogotypeReverseWhite from "./logotype-reverse-white.svg"
import LogotypeTeal from "./logotype-teal.svg"
import LogotypeWatermarkBlack from "./logotype-watermark-black.svg"
import LogotypeWatermarkWhite from "./logotype-watermark-white.svg"
import LogotypeWhite from "./logotype-white.svg"
import SocialApple from "./social-apple.svg"
import SocialGoogle from "./social-google.svg"

export {
    LogotypeDefault,
    LogotypePurple,
    LogotypeTeal,
    LogotypeWhite,
    LogotypeWatermarkWhite,
    LogotypeReverseBlack,
    LogotypeReverseWhite,
    LogotypeBlack,
    LogotypeWatermarkBlack,
    LogogramDefault,
    LogogramTeal,
    LogogramPurple,
    CurrencyIdr,
    SocialGoogle,
    SocialApple
}
