import { useTranslation } from "next-i18next"
import tw from "twin.macro"

import BaseIndicator from "./BaseIndicator"

const NewIndicatorWrapper = tw(BaseIndicator)`
    w-fit h-[20px]
    py-0.5 px-1
    flex items-center justify-center
`

const NewIndicator = () => {
    const { t } = useTranslation("common")

    return (
        <NewIndicatorWrapper>
            <span className='uppercase caption-2 text-color-bg dark:text-dark-color-bg'>{t("new")}</span>
        </NewIndicatorWrapper>
    )
}

export default NewIndicator
