import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchHistoryTransAssets, fetchHistoryTransAssetsPagination } from "./actions"

const historyAssetsSlicer = createSlice({
    name: "historyAssets",
    initialState: {
        historyAssets: null as HistoryAssetsModel[] | null,
        assetsLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchHistoryTransAssets.pending.type]: (state) => {
            state.assetsLoading = true
        },
        [fetchHistoryTransAssets.fulfilled.type]: (state, action: PayloadAction<HistoryAssetsModel[]>) => {
            state.historyAssets = action.payload.map((history) => ({
                transaction_id: history.transaction_id,
                id: history.id,
                wid: history.wid,
                did: history.did,
                mi: history.mi,
                datetime: history.datetime,
                initial_date: history.initial_date,
                account_name: history.account_name,
                account_code: history.account_code,
                trans_type: history.trans_type,
                status: history.status,
                from_address: history.from_address,
                to_address: history.to_address,
                label_address: history.label_address,
                amount: history.amount,
                total_done: history.total_done,
                trx_amount: history.trx_amount,
                fee: history.fee,
                trans_fee: history.trans_fee,
                sms_fee: history.sms_fee,
                vat: history.vat,
                digits: history.digits,
                decimals: history.decimals,
                digits_new: history.digits_new,
                tx_id: history.tx_id,
                explorer_url: history.explorer_url,
                chain_name: history.chain_name,
                hash: history.hash,
                is_internal: history?.is_internal
            }))
            state.assetsLoading = false
        },
        [fetchHistoryTransAssetsPagination.fulfilled.type]: (state, action: PayloadAction<HistoryAssetsApi[]>) => {
            state.historyAssets?.push(
                ...action.payload.map((history) => ({
                    transaction_id: history.transaction_id,
                    id: history.id,
                    wid: history.wid,
                    did: history.did,
                    mi: history.mi,
                    datetime: history.datetime,
                    initial_date: history.initial_date,
                    account_name: history.account_name,
                    account_code: history.account_code,
                    trans_type: history.trans_type,
                    status: history.status,
                    from_address: history.from_address,
                    to_address: history.to_address,
                    amount: history.amount,
                    total_done: history.total_done,
                    trx_amount: history.trx_amount,
                    fee: history.fee,
                    trans_fee: history.trans_fee,
                    sms_fee: history.sms_fee,
                    vat: history.vat,
                    digits: history.digits,
                    decimals: history.decimals,
                    digits_new: history.digits_new,
                    tx_id: history.tx_id,
                    explorer_url: history.explorer_url,
                    chain_name: history.chain_name,
                    hash: history.hash,
                    is_internal: history?.is_internal
                }))
            )
        }
    }
})

export default historyAssetsSlicer
